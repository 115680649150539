import { ElNotification } from 'element-plus';
import { useApp } from '~/stores/app.store';
import { $t } from '~/i18n';

export const consoleHandle = (error: any, ctx: any) => console.log('Hello from handler', error.message, ctx);

export const alertHandler = (error: any, ctx: any) => {
  const app = useApp();
  app.setError(error);
};

export const notificationHandler = (error: any, ctx: any) => {
  let message = error;

  if (error?.response?.data?.error?.code) {
    message = $t(`errors.${error.response.data.error.code}`);
  }

  ElNotification({
    message,
    type: 'warning',
  });
};

export default notificationHandler;
