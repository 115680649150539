<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import {
  PButton,
  PCheckbox,
  PDialog,
  PHint,
  IconCrossMd as CrossMdIcon,
  IconCrossSm as CrossSmIcon,
  IconCheckSm as CheckIcon,
  IconCheckboxActive as CheckBoxIcon,
  IconInfoSm as InfoIcon,
} from '@profeat/ui-kit';

import UnionIcon from '~/assets/icons/Union.svg';
import { useTariffs } from '~/stores/tariffs.store';
import { usePayment } from '~/stores/payment.store';
import { useUser } from '~/stores/user.store';
import { getSeparatorNumber } from '~/utils/string-helper';

const tariffsStore = useTariffs();
const paymentStore = usePayment();
const userStore = useUser();
const { t } = useI18n();

const loading = ref(false);

const minimalCostPerMessage = computed(() => {
  const costs = Object.values(tariffsStore.templateCost);

  return String(Math.min(...costs)).replace('.', ',');
});
const expired = computed(() => {
  const duration = tariffsStore.getCurrentTariff.duration;
  const durationUnit = tariffsStore.getCurrentTariff.durationUnit;
  const paymentMode = paymentStore.getMode;
  let date = dayjs().add(duration, durationUnit).format('DD.MM.YYYY');

  if (paymentMode === 'renew') {
    date = dayjs(userStore.subscriptionActiveTo).add(duration, durationUnit).format('DD.MM.YYYY');
  }

  return date;
});

const onApprove = async () => {
  loading.value = true;
  await paymentStore.purchase();
  loading.value = false;
};

watch(
  () => paymentStore.isDialogVisible,
  (dialogVisible) => {
    if (!dialogVisible) paymentStore.isAutoRenew = true;
  },
);
</script>

<template>
  <PDialog v-model="paymentStore.isDialogVisible" width="small">
    <template #header>
      <header class="header">
        <p class="tariff-title">
          {{ $tc('paymentTariffs.payment', { tariff: tariffsStore.getCurrentTariff.title }) }}
        </p>
        <div class="flex cursor-pointer" @click="paymentStore.setDialogVisible(false)">
          <CrossMdIcon />
        </div>
      </header>
    </template>

    <body class="main">
      <div class="tariff-info">
        <div class="info">
          <p>{{ $t('menu.mass-mailing') }}</p>
          <div class="flex">
            <CheckIcon class="color-[#2db2e3]" />
          </div>
        </div>
        <div class="info">
          <p>{{ $t('tariffs.triggerMailing') }}</p>
          <div class="flex">
            <CheckIcon v-if="tariffsStore.getCurrentTariff.hasTriggerMailing" class="color-[#2db2e3]" />
            <CrossSmIcon v-else class="color-[#E46060]" />
          </div>
        </div>
        <div class="info">
          <p>{{ $t('tariffs.chatBotBuilder') }}</p>
          <div class="flex">
            <CheckIcon v-if="tariffsStore.getCurrentTariff.hasChatBot" class="color-[#2db2e3]" />
            <CrossSmIcon v-else class="color-[#E46060]" />
          </div>
        </div>
        <div v-if="!userStore.user.isWzIntegration" class="info">
          <p>{{ $t('paymentTariffs.modal.payment.cost one message') }}</p>
          <span>{{ `от ${minimalCostPerMessage} ₽` }}</span>
        </div>
        <div class="info">
          <p>{{ $t('paymentTariffs.modal.payment.validity period') }}</p>
          <span>{{ `до ${expired}` }}</span>
        </div>
        <div class="info">
          <span>{{ $t('paymentTariffs.modal.payment.total cost') }}</span>
          <span>
            {{ `${getSeparatorNumber(tariffsStore.getCurrentTariff.cost)} ₽` }}
          </span>
        </div>
      </div>

      <div class="payment-method">
        <p>{{ $t('paymentTariffs.methods.title') }}</p>

        <div class="method">
          <div class="flex">
            <UnionIcon />
          </div>
          <p>{{ $t('paymentTariffs.methods.by bank card') }}</p>
          <div class="flex">
            <CheckBoxIcon />
          </div>
        </div>

        <div class="auto-pay">
          <PCheckbox
            v-model="paymentStore.isAutoRenew"
            label="Автопродление"
            label-position="right"
            text-size="sm"
            class="auto-renew"
          />

          <PHint placement="right">
            <template #activator="{ setReference, onHover, outHover }">
              <div :ref="setReference" @mouseover="onHover" @mouseleave="outHover" class="flex">
                <InfoIcon class="color-[#B6C7D6]" />
              </div>
            </template>
            <template #hint>
              <span>{{ $t('tariffs.extendedAutomatically') }}</span>
            </template>
          </PHint>
        </div>
      </div>
    </body>

    <template #footer>
      <footer class="footer">
        <PButton class="w-full" :disabled="loading" @click="onApprove">
          {{ $t('paymentTariffs.modal.payment.pay') }}
        </PButton>
        <p>
          {{ $t('paymentTariffs.methods.offer.first part') }}
          <a target="_blank" href="https://sendbot.yourgood.ru/offer" class="offer-link">
            {{ $t('paymentTariffs.methods.offer.second part') }}
          </a>
        </p>
      </footer>
    </template>
  </PDialog>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;

  padding: 12px 16px;
  border-bottom: 1px solid var(--deviders-primary);

  .tariff-title {
    width: 100%;

    color: var(--common-black);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
}

.main {
  margin-top: 24px;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  .tariff-info {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid var(--common-rain, #d0dbe4);

    display: flex;
    flex-direction: column;
    gap: 8px;

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: var(--common-black);
        font-size: 14px;
        line-height: 143%;
      }

      span {
        color: var(--common-black);
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }

  .payment-method {
    display: flex;
    flex-direction: column;
    gap: 16px;

    p {
      color: var(--common-asphalt);
      font-size: 14px;
      font-weight: 600;
      line-height: 143%;
    }

    .method {
      padding: 16px;
      border-radius: 16px;
      background: var(--common-fog);

      display: flex;
      align-items: center;
      gap: 12px;

      p {
        flex: 1;

        color: var(--typography-titles);
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
      }
    }

    .auto-pay {
      display: flex;
      align-items: center;
    }
  }
}

.footer {
  margin-top: 32px;
  padding: 0 16px 40px 16px;

  p {
    text-align: center;
    margin-top: 12px;

    color: var(--common-asphalt);
    font-size: 14px;
    line-height: 143%;
  }

  a {
    color: var(--color-primary, #2db2e3);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}

.auto-renew {
  :deep(.label) {
    font-weight: 400 !important;
  }
}
</style>
