<script lang="ts" setup>
import { ref, shallowRef, watch } from 'vue';
import { useRoute } from 'vue-router';

import AuthLayout from './AuthLayout.vue';
import MainLayout from './MainLayout.vue';

const LAYOUTS = {
  AuthLayout,
  MainLayout,
};

const layout = shallowRef(AuthLayout);
const loading = ref(true);

const route = useRoute();

const getLayout = () => {
  const { meta, path } = route;

  if (path == '/') {
    return AuthLayout;
  }

  if (!meta?.layout) {
    return MainLayout;
  }

  // @ts-ignore
  return LAYOUTS[meta.layout] || MainLayout;
};

watch(
  () => route.meta,
  async () => {
    layout.value = await getLayout();
    loading.value = route.path == '/';
  },
);
</script>

<template>
  <div class="wrapper">
    <BasePreloader v-if="loading" class="preloader" />
    <component v-else :is="layout" />
    <BaseNotificationList />
    <PTeleportContainer />
    <PToastContainer class="toast" />
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  height: calc(100vh);
}
.preloader {
  height: calc(100vh);
  width: calc(100vw);
  display: flex;
  align-items: center;
  justify-content: center;
}

.toast {
  white-space: pre-wrap;

  :deep(.p-toast) {
    white-space: pre-wrap;
  }

  :deep(.content-text) {
    -webkit-line-clamp: unset !important;
  }
}
</style>
