<script setup lang="ts">
import { ref } from 'vue';

const visibleModel = ref<boolean>(false);
const iframeLink = ref<string>('');

const show = (link: string) => {
  iframeLink.value = link;
  visibleModel.value = true;
};

defineExpose({
  show,
});
</script>

<template>
  <PDialog v-model="visibleModel" width="medium">
    <template #header> </template>
    <div class="main">
      <iframe :src="iframeLink" frameborder="0"></iframe>
    </div>
    <template #footer> </template>
  </PDialog>
</template>

<style scoped>
.main {
  box-sizing: border-box;
  padding: 12px;
}
iframe {
  width: 100%;
  min-height: 60vh;
}
</style>
