import { BaseCrudApi } from './abstract/base-crud.api';
import { WazzupTemplateType } from '~/entities/template';
import { CatchAll } from '~/error/catch';
import { CatchWzApi } from '~/error/wz-api-catch';

export class TemplatesApi extends BaseCrudApi {
  constructor() {
    super('templates');
  }

  async getIframe(type: WazzupTemplateType) {
    return this.api.post<{ link: string }>(`iframe/${type}`).then(({ data }) => data);
  }

  @CatchWzApi()
  @CatchAll()
  async findManyAndSync<T extends object>(query?: T) {
    return this.api.get('sync', { params: query }).then(({ data }) => data);
  }
}
