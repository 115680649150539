import { BaseApi } from './abstract/base.api';
export class AppApi extends BaseApi {
  constructor() {
    super();
  }

  async healthCheck() {
    const result = await this.api.get('/healthcheck');
    return result;
  }
}


