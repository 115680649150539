import { Tariff } from './tariffs';
import { Operation } from '~/entities/operation';

export enum SubscriptionStatus {
  Pending = 'pending',
  WaitingForChannel = 'waiting',
  waitingToTurn = 'waitingTurn',
  Active = 'active',
  Closed = 'closed',
}

export interface Subscription {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  previousId: string | null;
  tariffId: string | null;
  activeFrom: Date | null;
  activeTo: Date | null;
  status: SubscriptionStatus;
  autoRenew: boolean;
  dialogsCount: number;
  paymentMethodId: string | null;
  tariff: Tariff;
  chainId: string;
  operationId: string | null;
  operation?: Operation;
}

export interface CreateGiftResponse {
  subscription: Omit<Subscription, 'tariff'>
}
