<script lang="ts" setup>
import { computed } from 'vue';

import AttentionIcon from '~/assets/icons/attention.svg';
import CheckedIcon from '~/assets/icons/checked.svg';
import CloseIcon from '~/assets/icons/close.svg';
import { Notification, NotificationType, useNotification } from '~/stores/notification.store';

const { removeNotification } = useNotification();

const { notification } = defineProps<{ notification: Notification }>();

const getClass = computed(() => `notification ${notification.type}`);
</script>

<template>
  <div :class="getClass" :style="{ width: notification.width ? `${notification.width}px` : undefined }">
    <i v-if="notification.type === NotificationType.error" class="notification-icon">
      <AttentionIcon />
    </i>
    <i v-else-if="notification.type === NotificationType.success" class="notification-icon">
      <CheckedIcon />
    </i>

    <div class="notification-message">
      <span>{{ notification.message }}</span>
    </div>

    <i @click="() => removeNotification(notification.id)" class="close-icon">
      <CloseIcon />
    </i>
  </div>
</template>

<style lang="scss" scoped>
.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 8px;
  background: $ui-white;
  color: $ui-white;

  padding: 16px;
  margin-top: 15px;
  border-radius: 4px;

  line-height: 24px;

  i {
    display: flex;
  }

  &-message {
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    word-break: break-word;
  }

  &-icon {
    :deep(svg) {
      path {
        fill: $ui-white;
      }
    }
  }

  .close-icon {
    cursor: pointer;

    :deep(svg) {
      path {
        fill: $ui-white;
      }
    }
  }
}

.error {
  background: $ui-error;
}

.default {
  background: #424242;
}

.info {
  background: $ui-background-info-light;
}

.warning {
  background: $ui-background-warn-light;
}

.success {
  background: $ui-accent;
}
</style>
