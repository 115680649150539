import { defineStore } from "pinia";
import {
  Column,
  CSVRecord,
  DataType,
  FetchExistParams,
  Tab,
  Tabs,
  Tag,
} from "~/components/contacts/types";
import { api } from "~/api";
import Papa from "papaparse";
import { v4 as uuidv4 } from "uuid";
import { $t, $te } from "~/i18n";
import { CustomFieldType, SystemFieldType } from "~/entities/contacts";
import { transformPhoneNumber } from "~/components/contacts/helper";
import dayjs from "dayjs";

// Валидаторы для типов полей
function validatePhoneNumber(phoneNumber: string): boolean {
  const pattern: RegExp = /^[0-9+()\-\s]+$/;
  return (
    phoneNumber.toString().replace(/\D/g, "").length > 10 &&
    pattern.test(phoneNumber)
  );
}
function validateDate(dateString: string): boolean {
  if (dateString.length < 1) return true;

  // Паттерн для даты в формате ДД.ММ.ГГГГ
  const pattern: RegExp = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;
  return pattern.test(dateString);
}
function validateNumber(numberString: string): boolean {
  if (numberString.length < 1) return true;

  // Паттерн для числа с плавающей точкой или запятой
  const pattern: RegExp = /^-?\d+([.,]\d+)?$/;
  return pattern.test(numberString);
}

function validateTelegram(telegramString: string): boolean {
  if (telegramString.length < 1) return true;

  // Паттерн для строки только буквы, цифры и символ _, длина от 5 до 32 символов
  const pattern: RegExp = /^[a-zA-Z0-9_]{5,32}$/;
  return telegramString.startsWith("@")
    ? pattern.test(telegramString.slice(1))
    : pattern.test(telegramString);
}

interface EditData {
  rowIndex: number;
  colIndex: number;
  value: string;
}

interface State {
  isLoading: boolean;
  viewCount: number;
  data: CSVRecord[];
  headers: Column[];
  currentTab: Tabs;
  defaultColumns: Array<Column>;
  customColumns: Array<Column>;
  defaultTags: Array<Tag>;
  customTags: Array<Tag>;
  selectedColumn: string | null;
  existRecords: Record<"phone" | "telegram", Set<string>>;
  uniqueRecords: Record<"phone" | "telegram", Map<string, number>>;
}

export const useUploadStore = defineStore("upload", {
  state: (): State => ({
    isLoading: false,
    viewCount: 20,
    data: [],
    headers: [],
    currentTab: "all",
    defaultColumns: [],
    customColumns: [],
    defaultTags: [],
    customTags: [],
    selectedColumn: null,
    existRecords: {
      phone: new Set(),
      telegram: new Set(),
    },
    uniqueRecords: {
      phone: new Map(),
      telegram: new Map(),
    },
  }),
  actions: {
    resetState() {
      this.viewCount = 20;
      this.data = [];
      this.headers = [];
      this.currentTab = "all";
      this.defaultColumns = [];
      this.customColumns = [];
      this.defaultTags = [];
      this.customTags = [];
      this.selectedColumn = null;
      this.existRecords = {
        phone: new Set(),
        telegram: new Set(),
      };
      this.uniqueRecords = {
        phone: new Map(),
        telegram: new Map(),
      };
    },
    setDefaultTags(tags: Array<Tag>) {
      this.defaultTags = tags;
    },
    addCustomTag(tag: Tag) {
      this.customTags.push(tag);
    },
    setDefaultColumns(columns: Array<Column>) {
      this.defaultColumns = columns;
    },
    addCustomColumn(column: Column) {
      this.customColumns.push(column);
    },
    /**
     * Инициализация существующих тегов
     */
    async fetchTags() {
      const tags = await api.uploadContacts.getTags();
      this.setDefaultTags(
        tags.map((tag) => ({ name: tag.name, enabled: false }))
      );
    },
    createCustomTag(name: string) {
      const tag = { name, enabled: false };
      this.customTags.push(tag);
      return tag;
    },
    updateOrCreateSelectedTags(tagNames: string[]) {
      this.allTags.forEach((tag) => {
        tag.enabled = false;
      });
      tagNames.forEach((tagName) => {
        const tag = this.allTags.find((tag) => tag.name === tagName);
        if (tag) {
          tag.enabled = true;
        }
      });
    },
    /**
     * Инициализация существующих колонок
     */
    async fetchColumns() {
      const columns = await api.uploadContacts.getFields();
      this.setDefaultColumns(columns);
    },
    /**
     * Инициализация существующих данных по типу
     */
    async fetchExist(params: FetchExistParams) {
      this.isLoading = true;
      const result = await api.uploadContacts.getExist(params);
      this.existRecords[params.type] = new Set(result);
      this.isLoading = false;
    },
    /**
     * Установить существующие данные по типу
     *
     * @param index Индекс колонки
     */
    async setExistRecords(index: number) {
      const type = this.headers[index].dataType as "phone" | "telegram";
      if (!type || !["phone", "telegram"].includes(type)) return;
      const value = this.data
        .filter((row) => row[index].isValid)
        .map((row) => row[index].value);
      const params: FetchExistParams = { value, type };
      await this.fetchExist(params);
      this.data.forEach((row) => {
        row[index].isExist = this.existRecords[type].has(row[index].value);
      });
    },
    /**
     * Сохраненире текущей загрузки контактов
     */
    async uploadContacts() {
      const dataTypeMap = {
        date: CustomFieldType.Date,
        number: CustomFieldType.Int,
        string: CustomFieldType.Text,
        phone: SystemFieldType.Phone,
        telegram: SystemFieldType.String,
      };

      const headers = this.headers
        .filter((header) => header.name)
        .map((header) => ({
          name: header.name!,
          type: dataTypeMap[header.dataType!],
        }));
      const headerMap: {
        [index: number]: { skip: boolean; type: DataType };
      } = this.headers.reduce((acc, header, index) => {
        return {
          ...acc,
          [index]: { skip: header.skip, type: header.dataType },
        };
      }, {});
      const body: (string | null)[][] = [];
      const tags = this.selectedTags.map(({ name }) => name);

      rows: for (const values of this.data) {
        const rowValues: (string | null)[] = [];

        for (let index = 0; index < values.length; index++) {
          let { value, isDuplicate, isValid } = values[index];
          const isEmptyHeader = typeof headerMap[index] === "undefined";

          if (isDuplicate || !isValid) continue rows;
          if (isEmptyHeader || headerMap[index].skip) continue;

          if (headerMap[index].type === "date" && value) {
            const date = dayjs(value, "DD.MM.YYYY", true);
            if (!date.isValid()) continue rows;
            value = date.toISOString();
          }
          if (headerMap[index].type === "telegram" && value) {
            value = value.startsWith("@") ? value.slice(1) : value;
          }
          if (headerMap[index].type === "number" && value) {
            value = value.replace(",", ".");
          }

          rowValues.push(!!value ? value : null);
        }

        body.push(rowValues);
      }

      await api.uploadContacts.upload({ data: body, fields: headers, tags });
    },
    /**
     * Обновляет флаг существования после редактирования значения
     */
    updateExistAfterEdit({ rowIndex, colIndex, value }: EditData) {
      const header = this.headers[colIndex];
      if (header.dataType === "phone" || header.dataType === "telegram") {
        this.data[rowIndex][colIndex].isExist =
          this.existRecords[header.dataType].has(value);
      }
    },
    /**
     * Сбрасывает флаг существования
     */
    resetExistRecords(index: number) {
      this.data.forEach((row) => (row[index].isExist = false));
    },
    /**
     * Вычисляет уникальные ячейки, помечает дубликаты
     */
    setUniqueRecords(index: number) {
      const type = this.headers[index].dataType as "phone" | "telegram";
      if (!type || !["phone", "telegram"].includes(type)) return;
      this.resetUniqueRecords(index);
      this.resetDuplicateRecords(index);
      this.data.forEach((row) => {
        if (this.uniqueRecords[type].has(row[index].value)) {
          row[index].isDuplicate = true;
          this.uniqueRecords[type].set(
            row[index].value,
            this.uniqueRecords[type].get(row[index].value)! + 1
          );
        } else {
          this.uniqueRecords[type].set(row[index].value, 1);
        }
      });
    },
    resetUniqueRecords(index: number) {
      const header = this.headers[index];
      if (header.dataType === "phone" || header.dataType === "telegram") {
        this.uniqueRecords[header.dataType] = new Map();
      }
    },
    resetDuplicateRecords(index: number) {
      this.data.forEach((row) => (row[index].isDuplicate = false));
    },
    /**
     * Парсинг файла/данных
     * Также добавляет колонки по умолчанию
     *
     * @param data - Файл или строка с данными для парсинга
     * @param delimiter - разделитель, по умолчанию автоопределение (пустая строка)
     * @returns {boolean} — возвращает результат удачности парса данных
     */
    async parseFile(data: File | string, delimiter = ""): Promise<boolean> {
      return new Promise((resolve) => {
        Papa.parse(data, {
          header: false,
          delimiter,
          skipEmptyLines: true,
          complete: async (results: Papa.ParseResult<CSVRecord>) => {
            // Если ошибка произошла с разделителем по умолчанию
            // пробуем с разделителем переноса строки (для данных с одним столбцом)
            if (results.errors.length > 0 && delimiter === "") {
              const result = await this.parseFile(data, "\n");
              resolve(result);
              return;
            }
            if (results.errors.length > 0) {
              console.error("Error parsing CSV:", results.errors);
              resolve(false);
              return;
            }

            /**
             * Фильтрация строк, содержащих хотя бы одну заполненную ячейку.
             * Избегает парсинга строк формата: ";;;", где все ячейки пустые.
             */
            const filledRows = results.data.filter((row) =>
              row.some((cell: any) => cell.trim() !== "")
            );
            /**
             * Нахождение самой длинной строки, чтобы нормализовать длину всех строк.
             * Это позволяет добавить недостающие пустые ячейки к более коротким строкам.
             */
            const longestRow = filledRows.reduce((longest, current) => {
              return current.length > longest.length ? current : longest;
            }, []);
            /**
             * Нормализация данных: добавление пустых ячеек к строкам,
             * длина которых меньше самой длинной строки.
             */
            const normalizedData = filledRows.map((row: any) => {
              while (row.length < longestRow.length) {
                row.push("");
              }
              return row;
            });
            this.initDefaultHeaders(longestRow.length);
            this.initData(normalizedData);
            resolve(true);
          },
        });
      });
    },
    /**
     * Инициализирует заголовки по умолчанию
     */
    initDefaultHeaders(count: number) {
      for (let i = 0; i < count; i++) {
        const item: Column = {
          uuid: uuidv4(),
          name: null,
          dataType: null,
          columnType: null,
          skip: false,
        };
        this.headers.push(item);
      }
    },
    /**
     * Инициализирует данные
     */
    initData(data: any[]) {
      this.data = data.map((row) => {
        return row.map((value: string) => ({
          value: value.trim(),
          isValid: true,
          isExist: false,
          isDuplicate: false,
        }));
      });
    },
    /**
     * Редактирование данных в ячейке таблицы
     */
    dataEdit({ rowIndex, colIndex, value }: EditData) {
      const header = this.headers[colIndex];
      this.data[rowIndex][colIndex].value = value.trim();
      if (!header.dataType) return;
      const isValid = this.validate(
        header.dataType,
        this.data[rowIndex][colIndex].value
      );
      this.data[rowIndex][colIndex].isValid = isValid;
    },
    /**
     * Валидация данных в зависимости от типа
     */
    validate(type: DataType, value: string): boolean {
      const validations = {
        ["phone"]: validatePhoneNumber,
        ["date"]: validateDate,
        ["number"]: validateNumber,
        ["string"]: (_: string) => true,
        ["telegram"]: validateTelegram,
      };
      return validations[type](value);
    },
    /**
     * Создает кастомную колонку
     */
    createCustomColumn(name: string, type: Column["dataType"]): Column {
      const newColumn: Column = {
        uuid: uuidv4(),
        name: name,
        dataType: type,
        columnType: "user",
        skip: false,
      };
      this.addCustomColumn(newColumn);
      return newColumn;
    },
    /**
     * Выбор колонки
     */
    selectColumn(uuid: string, index: number) {
      const column = this.allColumns.find((el) => el.uuid === uuid)!;
      this.headers[index] = { ...column };
      this.validateColumn(index);
      this.formatPhone(index);
    },
    /**
     * Валидация данных в колонке
     */
    validateColumn(index: number) {
      if (this.headers[index] === null) return;

      for (let i = 0; i < this.data.length; i++) {
        const cell = this.data[i][index];
        cell.isValid = !this.headers[index].dataType
          ? true
          : this.validate(this.headers[index].dataType!, cell.value) ||
            this.headers[index].skip;
      }
    },
    /**
     * форматирование номеров телефона
     */
    formatPhone(index: number) {
      if (this.headers[index] === null) return;

      for (let i = 0; i < this.data.length; i++) {
        const cell = this.data[i][index];
        if (cell.isValid && this.headers[index].dataType === "phone") {
          cell.value = transformPhoneNumber(cell.value);
        }
      }
    },
    /**
     * Помечает колонку как пропущенную
     */
    skipColumn(index: number) {
      this.resetExistRecords(index);
      this.resetUniqueRecords(index);
      this.resetDuplicateRecords(index);

      const header = this.headers[index];

      header.name = null;
      header.columnType = null;
      header.dataType = null;
      header.skip = true;

      this.validateColumn(index);
    },
    /**
     * Удаляет строку
     */
    deleteRow(rowIndex: number) {
      this.data.splice(rowIndex, 1);
    },
    /**
     * Увеличивает количество отображаемых строк на странице
     */
    increaseViewCount(count: number) {
      this.viewCount += count;
    },
  },
  getters: {
    newData(state): CSVRecord[] {
      const hasUniqueHeader = this.hasUniqueHeader as boolean;
      return hasUniqueHeader
        ? state.data.filter(
            (row) =>
              !row.some(
                (el, index) =>
                  state.headers[index]?.dataType === "phone" &&
                  (el.isDuplicate || el.isExist || !el.isValid)
              )
          )
        : [];
    },
    existData: (state): CSVRecord[] => {
      const hasUniqueHeader = state.headers.some(
        (el) => el.dataType === "phone" || el.dataType === "telegram"
      );
      return hasUniqueHeader
        ? state.data.filter((row) =>
            row.some((el: any) => el.isExist && !el.isDuplicate)
          )
        : [];
    },
    errorData: (state): CSVRecord[] => {
      return state.data.filter((row) =>
        row.some((el: any) => !el.isValid || el.isDuplicate)
      );
    },
    currentData(state) {
      const currentTab = this.currentTab as Tabs;
      const newData = this.newData as CSVRecord[];
      const existData = this.existData as CSVRecord[];
      const errorData = this.errorData as CSVRecord[];
      if (currentTab === "new") {
        return newData;
      }
      if (currentTab === "exist") {
        return existData;
      }
      if (currentTab === "error") {
        return errorData;
      }
      return state.data;
    },
    viewData() {
      const currentData = this.currentData as CSVRecord[];
      return currentData.sort((a, b) => {
        const aIndex = a.findIndex((el) => el.isValid === false);
        const bIndex = b.findIndex((el) => el.isValid === false);
        if (aIndex > bIndex) return -1;
        if (aIndex < bIndex) return 1;
        return 0;
      });
    },
    tabs(state): Tab[] {
      const newData = this.newData as CSVRecord[];
      const existData = this.existData as CSVRecord[];
      const errorData = this.errorData as CSVRecord[];
      return [
        {
          text: "Все",
          value: "all",
          count: state.data.length.toString(),
        },
        {
          text: "Новые",
          value: "new",
          count: newData.length.toString(),
        },
        {
          text: "Существующие",
          value: "exist",
          count: existData.length.toString(),
        },
        {
          text: "Ошибка",
          value: "error",
          count: errorData.length.toString(),
        },
      ];
    },
    allTags: (state) => [...state.defaultTags, ...state.customTags],
    selectedTags: (state) =>
      [...state.defaultTags, ...state.customTags].filter((el) => el.enabled),
    allColumns: (state) => [...state.defaultColumns, ...state.customColumns],
    unusedColumns(state) {
      const allColumns = this.allColumns as Column[];
      return allColumns
        .filter(
          (el) => !state.headers.some((h) => h.uuid === el.uuid && !h.skip)
        )
        .map((column) => {
          const path = `customers.header.${column.name}`;
          const name = $te(path) ? $t(path) : column.name;

          return {
            ...column,
            name,
          };
        })
        .sort((a, b) => a.name!.localeCompare(b.name!));
    },
    viewDataCount(state) {
      const viewData = this.viewData as CSVRecord[];
      return Math.min(state.viewCount, viewData.length);
    },
    allDataCount: (state): number => state.data.length,
    hasUniqueHeader: (state) =>
      state.headers.some((h) => h.dataType === "phone"),
    isHeaderInit: (state) =>
      !state.headers.some((h) => h.name === null && !h.skip),
    hasValidRow: (state): boolean => {
      const firstValidRow = state.data.find((row) => {
        return row.every(({ isValid }) => isValid);
      });

      return !!firstValidRow;
    },
  },
});
