<script setup lang="ts">
import { useProfile } from '~/stores/profile.store';

const profileStore = useProfile();

</script>

<template>
  <div class="profile">
    <ProfileAlert />
    <div class="profile-plan-details">
      <div v-if="!profileStore.noChannel" class="profile-plan-details__top">
        <ProfileBalance />
        <ProfileWabaDialoguePrice />
      </div>
      <div class="profile-plan-details__bottom">
        <ProfileTariff />
      </div>
    </div>
    <div class="profile-channels-info">
      <ProfileChannel />
    </div>
  </div>
</template>

<style scoped>
.profile {
  display: flex;
  flex-direction: column;
}
.profile-plan-details {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}

.profile-plan-details__top {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
</style>
