import { BaseCrudApi } from './abstract/base-crud.api';
import { ChatbotStep } from '~/entities/chatbots';

export class ChatbotsStepApi extends BaseCrudApi {
  constructor() {
    super('chatbotstep');
  }

  async getByChatBotId(chatBotId: string) {
    return this.api
      .get<ChatbotStep[]>(`chatBot/${chatBotId}`)
      .then(({ data }) => data);
  }

  async createChatBot(id: string, file: any, chatbotId: string) {
    return this.api.post(
      id,
      {
        chatbotId: chatbotId,
        headerFile: file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  }
}
