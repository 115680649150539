<script setup lang="ts">
import { ref } from 'vue';
import { useAuth } from '~/stores/auth.store';
import { useChannels } from '~/stores/channel.store';

const channelsStore = useChannels();
const authStore = useAuth();

const modalWzSelectChannelVisible = ref<boolean>(false);
const loading = ref<boolean>(false);

const onSelectChannel = async (channelId: string) => {
  loading.value = true;

  const channel = channelsStore.wzChannels.find((el) => el.channelId === channelId);
  if (!channel) {
    loading.value = false;
    return;
  }
  await channelsStore.setWzChannel(channel);
  await authStore.fetchUser();

  loading.value = false;
};
</script>

<template>
  <ProfileModalWzSelectChannel
    v-model="modalWzSelectChannelVisible"
    :channels="channelsStore.wzChannels"
    :loading="loading"
    @select="onSelectChannel"
  />
  <div class="profile-channel__multiple">
    <span class="profile-channel__multiple-text">{{ $t('profile.alert.channel.noSelectedChannel') }}</span>
    <PButton :style="{ width: '154px' }" @click="modalWzSelectChannelVisible = true">{{
      $t('profile.channel.selectChannel')
    }}</PButton>
  </div>
</template>

<style scoped>
.profile-channel__multiple {
  box-sizing: border-box;
  padding: 16px;
  background-color: var(--common-fog);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-channel__multiple-text {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
</style>
