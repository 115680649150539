<script setup lang="ts">
import { onMounted, computed, ref, watch } from 'vue';
import { useUser } from '~/stores/user.store';
import { useStorage, useTitle, useFavicon } from '@vueuse/core';
import dayjs from 'dayjs';

interface GuestMode {
  id: string
  name: string
  uuid: string
  createdAt: number
};

const pageTitle = useTitle();
const pageFavicon = ref();
useFavicon(pageFavicon, {
  baseUrl: '/',
  rel: 'icon',
});
const userStore = useUser();
const guestMode = useStorage<GuestMode[]>('guest-mode', []);
const windowUuid = ref<string>(crypto.randomUUID());
const isValidWindow = computed(() => guestMode.value.length && guestMode.value[0].id === userStore.getUserId);
const lockIsInvalidStatus = ref(false);

function handleReset () {
  guestMode.value = guestMode.value.splice(0, 1);
}

watch(isValidWindow, val => {
  if (!val) {
    lockIsInvalidStatus.value = true
    pageTitle.value = `ЛК: ${userStore.getUserName}`;
    pageFavicon.value = 'error-favicon.png';
  }
})

onMounted(() => {
  if (userStore.guestView) {
    if (guestMode.value.length && guestMode.value[0].id === userStore.getUserId) {
      windowUuid.value = guestMode.value[0].uuid
      return;
    }
    guestMode.value.unshift({
      id: userStore.getUserId,
      name: userStore.getUserName,
      uuid: windowUuid.value,
      createdAt: Date.now(),
    });
  }
})
</script>

<template>
  <Teleport to="body">
    <div
      v-if="userStore.guestView"
      class="guest-wrapper"
    >
      <div class="guest-badge">
        <p>Гостевой режим: {{ userStore.user.name }}</p>
      </div>
      <div class="guest-line guest-line--left" />
      <div class="guest-line guest-line--right" />
      <div class="guest-line guest-line--top" />
      <div class="guest-line guest-line--bottom" />

      <div
        v-if="lockIsInvalidStatus"
        class="guest-body"
      >
        <div class="guest-body--alert">
          <div class="guest-body--warning">
            <pre class="guest-body--warning-icon">{{'{\t*\t}'}}</pre>
            <h4>Данное окно браузера устарело</h4>
            <p>Перейдите на последнее окно логин ссылки, либо перезагрузите страницу чтобы актуальные данные пользователя подгрузились</p>
          </div>
          <details
            v-for="(guest, guestIdx) in guestMode"
            :key="guest.uuid"
          >
            <summary class="guest-body--details">
              <p>
                {{ guest.name }} - {{ dayjs(guest.createdAt).format('DD.MM.YY HH:mm') }}
              </p>
              <template v-if="guestIdx === 0">
                <i
                  style="
                    font-family: monospace;
                    font-size: 8px;
                    margin: 0;
                    color: green;
                  "
                >Последний открытый в браузере</i>
              </template>
              <template v-if="guest.uuid === windowUuid">
                <i
                  style="
                    font-family: monospace;
                    font-size: 8px;
                    margin: 0;
                    color: red;
                  "
                >Текущее открытое окно</i>
              </template>
            </summary>
            <p
              v-for="(g, gKey) in guest"
              :key="`${guest.uuid}-${gKey}`"
              style="
                font-family: monospace;
                font-size: 10px;
                margin: 0;
              "
            >
              {{ gKey }}: {{ gKey === 'createdAt' ? dayjs(g) : g }}
            </p>
          </details>
          <button
            style="
              font-family: monospace;
              font-size: 10px;
              margin: 0;
              cursor: pointer;
              background-color: #fbc02d;
              padding: .5rem;
            "
            @click="handleReset()"
          >
            Сбросить историю
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<style scoped>
.guest-badge,
.guest-line,
.guest-body--alert,
.guest-body--warning-icon {
  --size: 8px;
  --accent: #fbc02d;
}

.guest-badge {
  position: fixed;
  z-index: 99;
  top: var(--size);
  padding: 8px 16px;
  left: 50%;
  transform: translate(-50%, 0);
  background: var(--accent);
  font-size: 16px;
  font-weight: 500;
}

.guest-line {
  z-index: 99;
  position: fixed;
  background: var(--accent);
  box-sizing: border-box;
}

.guest-line--left,
.guest-line--right {
  top: 0;
  width: var(--size);
  height: 100%;
}

.guest-line--left {
  left: 0;
}

.guest-line--right {
  right: 0;
}

.guest-line--top,
.guest-line--bottom {
  left: 0;
  width: 100%;
  height: var(--size);
}

.guest-line--top {
  top: 0;
}

.guest-line--bottom {
  bottom: 0;
}

.guest-body {
  position: fixed;
  z-index: 98;
  inset: 0;
  display: flex;
  justify-content: center;
  padding: calc(var(--size) * 4);
  overflow: auto;
  background-color: rgba(255,255,255, .15);
  backdrop-filter: blur(5px);
  border: solid var(--size) var(--accent);
}

.guest-body--alert {
  width: 400px;
  margin: auto;
  background-color: white;
  border: solid var(--size) var(--accent);
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding: .5rem;
}

.guest-body--warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .25rem;
  padding-top: 1rem;
  padding-bottom: .5rem;
}

.guest-body--warning h4,
.guest-body--warning p {
  text-align: center;
}

.guest-body--warning-icon {
  font-family: monospace;
  color: var(--accent);
  text-shadow: var(--accent) 1px 0 10px;
  tab-size: 2;
  transition: tab-size .5s;
  font-size: 1.5rem;
}

.guest-body--alert:hover .guest-body--warning-icon {
  tab-size: 6;
}

.guest-body--details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5rem;
}
</style>
