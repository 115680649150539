import { api } from "~/api";
import { useNotification, NotificationType } from "~/stores/notification.store";
import { useAuth } from "~/stores/auth.store";

export const setWzIntegrationTokenToLocalStorage = async (authStore: ReturnType<typeof useAuth>, token: string) => {
  if (await checkWzIntegrationToken(token)) {
    localStorage.setItem("wzIntegrationToken", token);
    authStore.setWzIntegrationToken(token);
  } else {
    notifyWzIntegrationTokenNotExists();
  }
};
export const restoreWzIntegrationTokenFromLocalStorage = async (authStore: ReturnType<typeof useAuth>) => {
  const token = localStorage.getItem("wzIntegrationToken") ?? "";
  if (token.length) {
    setWzIntegrationTokenToLocalStorage(authStore, token);
  }
};
export const removeWzIntegrationTokenFromLocalStorage = (authStore: ReturnType<typeof useAuth>) => {
  const token = localStorage.getItem("wzIntegrationToken") ?? "";
  if (token.length) {
    localStorage.removeItem("wzIntegrationToken");
    authStore.setWzIntegrationToken("");
  }
};

const checkWzIntegrationToken = async (token: string) => {
  const { isExists } = await api.auth.isWzUserExists(token);
  return isExists;
};

const notifyWzIntegrationTokenNotExists = () => {
  const { pushNotification } = useNotification();
  pushNotification({
    message: "Не существует пользователя с таким токеном",
    type: NotificationType.error,
  });
};
