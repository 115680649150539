<script lang="ts" setup>
import { useVModel } from '@vueuse/core';

// Icons
import IconChannelWaba from '~/assets/icons/profile-channel-waba.png';
import { WzIntegrationChannel } from '~/entities/channel';

const props = defineProps<{
  modelValue: boolean;
  channels: WzIntegrationChannel[];
  loading: boolean;
}>();
const emits = defineEmits<{
  (event: 'update:modelValue', payload: boolean): void;
  (event: 'select', channelId: string): void;
}>();

const visibleModel = useVModel(props, 'modelValue', emits);
const onClose = () => {
  emits('update:modelValue', false);
};
const onSelect = (channelId: string) => {
  if (props.loading) return;

  emits('select', channelId);
};
</script>

<template>
  <PDialog v-model="visibleModel" width="small">
    <template #header>
      <header>
        <p>{{ $t('profile.modals.selectChannel.title') }}</p>
        <IconCrossMd class="icon" @click="onClose" />
      </header>
    </template>
    <div class="main">
      <p>
        {{ $t('profile.modals.selectChannel.descriptionP1') }} <br />{{
          $t('profile.modals.selectChannel.descriptionP2')
        }}
      </p>
      <ul :class="{ loading: props.loading }">
        <li v-for="channel of channels" :key="channel.channelId" @click="onSelect(channel.channelId)">
          <img :src="IconChannelWaba" alt="" width="32" height="32" />
          <span>{{ channel.name }}</span>
          <PTag :color="'green'" text="Активен" />
        </li>
      </ul>
    </div>
    <template #footer></template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding: 12px 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

header p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

header .icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.main {
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.main > p {
  color: var(--common-asphalt);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.main > ul {
  list-style: none;
}

.main > ul > li {
  box-sizing: border-box;
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.main > ul > li > span {
  flex-grow: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.main > ul > li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.main > ul > li:hover {
  opacity: 64%;
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.loading > ul > li:hover {
  opacity: 1;
}
</style>
