import { BaseApi } from '~/api/abstract/base.api';
import { Company } from '~/entities/company';

export class CompanyApi extends BaseApi {
  constructor() {
    super();
  }

  async fetchCompany(userId: string): Promise<Company> {
    return this.api
      .get<Company>(`company/userId/${userId}`)
      .then(({ data }) => data);
  }
}
