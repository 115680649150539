import { RouteLocation, RouteRecord } from 'vue-router';

import { useProfile } from '~/stores/profile.store';
import { useAuth } from '~/stores/auth.store';

export const noActiveSubscriptionGuard = (allowedRoutes: RouteRecord[]) => (to: RouteLocation) => {
  const profile = useProfile();
  const authStore = useAuth();

  if (profile.hasExpiredSubscription && authStore.authorized) {
    const hasCurrentUrl = allowedRoutes.find(({ name }) => name === to.name);

    if (!hasCurrentUrl) {
      return { name: 'profile' };
    }
  }
};
