import { BaseCrudApi } from './abstract/base-crud.api';

interface ChainIntegrity {
  chatBotId: string;
  outputsCount: { [stepId: string]: number };
}

interface CloseChain {
  chatBotId: string;
  closeConnections: Array<{
    stepFromId: string;
    outputKey: string;
    coordX: number;
    coordY: number;
  }>;
  withConnection?: boolean;
}

export class ChatbotsConnectionsApi extends BaseCrudApi {
  constructor() {
    super('chatbot-connections');
  }

  async validateChainIntegrity(body: ChainIntegrity) {
    return this.api
      .post<{ isIntegrity: boolean; openedSteps: string[] }>(
        'validate-chain',
        body,
      )
      .then(({ data }) => data);
  }

  async closeChain(body: CloseChain) {
    return this.api
      .post('close-connection-chain', body)
      .then(({ data }) => data);
  }
}
