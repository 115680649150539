<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { PLoader } from '@profeat/ui-kit';

import { useChannels } from '~/stores/channel.store';
import { useUser } from '~/stores/user.store';

const userStore = useUser();
const channelsStore = useChannels();

const loading = ref(false);

onMounted(async () => {
  // Если юзер wz-интегрирован и у него нет каналов, то тянем каналы с wz
  if (userStore.user.isWzIntegration && !channelsStore.channels.length) {
    loading.value = true;

    await channelsStore.integrateWzChannels();

    loading.value = false;
  }
});
</script>

<template>
  <div class="profile">
    <ProfileWzAlert />
    <div class="profile-plan-details">
      <div class="profile-plan-details__bottom">
        <ProfileTariff />
      </div>
    </div>
    <div class="profile-channels-info">
      <div v-if="loading" class="loader">
        <PLoader />
      </div>
      <ProfileWzChannel v-else />
    </div>
  </div>
</template>

<style scoped>
.profile {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.profile-plan-details {
  display: flex;
  flex-direction: column;
}

.loader {
  padding: 23px 24px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
}
</style>
