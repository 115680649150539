<script setup lang="ts">
import { computed, ref } from 'vue';
import { SubscriptionStatus } from '~/entities/subscriptions';
import { useUser } from '~/stores/user.store';
import { useProfile } from '~/stores/profile.store';
import { useSubscription } from '~/composables/useSubscription';

const userStore = useUser();
const profileStore = useProfile();

// Тариф фри и закончился
const isExpitedNotRenew = computed(() => {
  return userStore.connectedTariff?.isGift && profileStore.hasTariffExpired;
});

// Срок действия тарифа закончился
const isTariffExpired = computed(() => profileStore.hasTariffExpired);

// Тариф скоро закончится
const isTariffExpires = computed(() => {
  return !userStore.connectedTariff?.isGift && profileStore.isTariffWillEnd;
});

// Пополнить баланс Wazzup
// Ждем эндпоинт на получение баланса с вазапа
const isWzBalanceLow = ref<boolean>(false);

const onRedirectWazzup = () => {
  window.location.href = 'https://wazzup24.com';
};

// Активация тарифа в процессе
const { subscriptionPending } = useSubscription();

const onPay = () => {};
</script>

<template>
  <div>
    <PAlert
      v-if="isExpitedNotRenew"
      v-model="isExpitedNotRenew"
      :show-cross="false"
      :hide-left-icon="false"
      color="error"
      :button-text="''"
    >
      {{ $t('profile.alert.isExpitedNotRenew') }}
    </PAlert>
    <PAlert
      v-else-if="isTariffExpired"
      v-model="isTariffExpired"
      :show-cross="false"
      :hide-left-icon="false"
      color="error"
      :button-text="''"
    >
      {{ $t('profile.alert.isExpited') }}
    </PAlert>
    <PAlert
      v-else-if="isWzBalanceLow"
      v-model="isWzBalanceLow"
      :show-cross="false"
      :hide-left-icon="false"
      color="error"
      :button-text="'Пополнить баланс'"
      @click:button="onRedirectWazzup"
    >
      {{ $t('profile.alert.isWzBalanceLow') }}
    </PAlert>
    <PAlert
      v-else-if="subscriptionPending"
      v-model="subscriptionPending"
      :show-cross="false"
      :hide-left-icon="false"
      color="warning"
      :button-text="'Оплатить'"
      @click:button="onPay"
    >
      {{ $t('profile.alert.subscriptionPending') }}
    </PAlert>
    <PAlert
      v-else-if="isTariffExpires"
      v-model="isTariffExpires"
      :show-cross="false"
      :hide-left-icon="false"
      color="info"
      :button-text="''"
    >
      {{ $t('profile.alert.tariffIsExpires') }}
    </PAlert>
  </div>
</template>

<style scoped></style>
