import { defineStore } from 'pinia';

import { Company } from '~/entities/company';
import { api } from '~/api';
import { useUser } from '~/stores/user.store';

interface State {
  company: Company;
}

export const useCompany = defineStore('company', {
  state: (): State => ({
    company: {
      id: '',
      balance: 0,
      externalCompanyId: '',
      paymentMethodId: '',
    },
  }),

  actions: {
    async fetchCompany(): Promise<void> {
      const userStore = useUser();

      if (!userStore.user) return;

      this.company = await api.company.fetchCompany(userStore.user.id);
    }
  },

  getters: {
    balance: (state: State) => state.company.balance,
    getCompanyId: (state: State) => state.company.id,
  },
});
