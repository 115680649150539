export const config = {
  apiUrl: process.env.VITE_API_URL,
  socketUrl: process.env.VITE_SOCKET_URL,
  host: process.env.VITE_HOST,
  nodeEnv: process.env.VITE_NODE_ENV,
  googleGTMId: process.env.VITE_GOOGLE_GTM_ID,
  ymId: process.env.VITE_YM_ID,
  pageSize: process.env.VITE_PAGE_SIZE || 100,
  widgetPfId: process.env.VITE_WIDGET_PF_ID,
  widgetSrc: process.env.VITE_WIDGET_SRC,
  sentryDsn: process.env.VITE_SENTRY_DSN,
  contactsApiUrl: process.env.VITE_CONTACT_SERVICE_API_URL,
};
