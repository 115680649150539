import { defineStore } from 'pinia';

import { api } from '~/api';
import { DurationUnit, Limit, Tariff, TemplateCost } from '~/entities/tariffs';
import { UpdateRenewalBody } from '~/api/subscriptions.api';
import { TemplateCategoryType } from '~/entities/template';
import { $t } from '~/i18n';
import { useUser } from './user.store';

const messageTemplateCategoryCost = Object.freeze({
  [TemplateCategoryType.AUTHENTICATION]: 6.24,
  [TemplateCategoryType.UTILITY]: 6.95,
  [TemplateCategoryType.MARKETING]: 11.68,
});

interface State {
  tariffs: Tariff[];
  currentTariff: Tariff;
  limits: Limit[];
  templateCost: TemplateCost;
}

export const useTariffs = defineStore('tariffs', {
  state: (): State => ({
    tariffs: [],
    currentTariff: {
      lineId: null,
      cost: 0,
      limit: 0,
      updatedAt: new Date(),
      createdAt: new Date(),
      duration: 0,
      innerCost: 0,
      id: '',
      extraCost: 0,
      isActive: false,
      isGift: false,
      discount: 0,
      title: '',
      durationUnit: DurationUnit.Month,
      amountSaved: 0,
      hasChatBot: false,
      hasTriggerMailing: false,
      isForIntegratedUser: false,
    },
    limits: [],
    templateCost: messageTemplateCategoryCost,
  }),

  actions: {
    async fetchTariffs() {
      const userStore = useUser();

      this.tariffs = await api.tariffs
        .findMany({
          isActive: true,
          isGift: false,
          isForIntegratedUser: userStore.user.isWzIntegration,
        })
        .then(({ data }) => data);
    },
    async fetchByLimit(limit: string) {
      const response = await api.tariffs.getByLimit(limit);
      this.tariffs = response.data;
    },
    async fetchLimits() {
      const limits = await api.tariffs.getLimits();
      this.limits = limits.sort((a, b) => a.limit - b.limit);
    },
    async updateRenewal(payload: UpdateRenewalBody) {
      const userStore = useUser();

      await api.subscriptions.updateRenewal(payload);

      userStore.setAutoRenewal(payload.isRenew);
    },
    async activateGift() {
      await api.subscriptions.createGift();
      await api.subscriptions.activateGift();
    },

    setTariffById(id: string) {
      const tariff = this.tariffs.find((el) => el.id === id);
      if (tariff) {
        this.currentTariff = tariff;
      }
    },
    setTariff(tariff?: Tariff) {
      if (!tariff) {
        return;
      }

      this.currentTariff = tariff;
    },
  },

  getters: {
    getTariffs: (state: State) => state.tariffs,
    getCurrentTariff: (state: State) => state.currentTariff,
    getLimits: (state: State) => state.limits,
    getTemplateCost: (state: State) => [
      {
        title: $t('profile.dialogs.incoming'),
        cost: 0,
      },
      {
        title: $t('profile.dialogs.marketing'),
        cost: state.templateCost.MARKETING,
      },
      {
        title: $t('profile.dialogs.authentication'),
        cost: state.templateCost.AUTHENTICATION,
      },
      {
        title: $t('profile.dialogs.utility'),
        cost: state.templateCost.UTILITY,
      },
    ],
  },
});
