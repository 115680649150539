<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVModel } from '@vueuse/core';
import {
  PButton,
  PDialog,
  PInput,
  IconCrossMd as CrossMdIcon,
  IconCheckboxActive as CheckBoxIcon,
} from '@profeat/ui-kit';

import { usePayment } from '~/stores/payment.store';
import UnionIcon from '~/assets/icons/Union.svg';

const { t } = useI18n();
const paymentStore = usePayment();

const props = defineProps<{ modelValue: boolean }>();
const emit = defineEmits<{
  (event: 'update:modelValue', payload: boolean): void;
}>();

const inputModel = ref('');
const isFetching = ref(false);
const hasError = ref(false);
const visibleModel = useVModel(props, 'modelValue', emit);

const onInput = () => (hasError.value = false);
const refill = async () => {
  isFetching.value = true;
  paymentStore.setPaymentMode('top-up');
  const amount = Number(String(inputModel.value).replace(/\D/g, ''));

  if (amount < 10) {
    hasError.value = true;
    isFetching.value = false;
    return;
  }
  if (amount > 5e5) {
    hasError.value = true;
    isFetching.value = false;
    return;
  }

  await paymentStore.purchase(amount);
  isFetching.value = false;
  emit('update:modelValue', false);
};

const errorMessage = computed(() =>
  hasError.value ? t('paymentTariffs.modal.balance replenishment.wrong amount') : '',
);
</script>

<template>
  <PDialog v-model="visibleModel" width="x-small">
    <template #header>
      <header class="header">
        <p class="header-title">
          {{ $t('paymentTariffs.modal.balance replenishment.title') }}
        </p>
        <div class="flex cursor-pointer" @click="emit('update:modelValue', false)">
          <CrossMdIcon />
        </div>
      </header>
    </template>

    <body class="main">
      <PInput
        v-model="inputModel"
        @update:model-value="onInput"
        size="large"
        type="number"
        :placeholder="$t('paymentTariffs.modal.balance replenishment.label')"
        :error="errorMessage"
      />

      <div class="payment-methods">
        <p class="payment-title">{{ $t('paymentTariffs.methods.title') }}</p>

        <div class="method">
          <div class="flex">
            <UnionIcon />
          </div>
          <p>{{ $t('paymentTariffs.methods.by bank card') }}</p>
          <div class="flex">
            <CheckBoxIcon />
          </div>
        </div>
      </div>
    </body>

    <template #footer>
      <footer class="footer">
        <PButton class="w-full" @click="refill" :disabled="isFetching">
          {{ $t('paymentTariffs.modal.balance replenishment.pay') }}
        </PButton>

        <p>
          {{ $t('paymentTariffs.methods.offer.first part') }}
          <a target="_blank" href="https://sendbot.yourgood.ru/offer">
            {{ $t('paymentTariffs.methods.offer.second part') }}
          </a>
        </p>
      </footer>
    </template>
  </PDialog>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;

  padding: 12px 16px;
  border-bottom: 1px solid var(--deviders-primary);

  &-title {
    width: 100%;

    color: var(--common-black);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
}

.main {
  margin-top: 24px;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  gap: 32px;

  .payment-methods {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .payment-title {
      color: var(--common-asphalt);
      font-size: 14px;
      font-weight: 600;
      line-height: 143%;
    }

    .method {
      padding: 16px;
      border-radius: 16px;
      background: var(--common-fog);

      display: flex;
      align-items: center;
      gap: 12px;

      p {
        flex: 1;

        color: var(--typography-titles);
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
      }
    }
  }
}

.footer {
  margin-top: 32px;
  padding: 0 16px 24px 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  p {
    text-align: center;

    color: var(--common-asphalt);
    font-size: 14px;
    line-height: 143%;
  }

  a {
    color: var(--color-primary, #2db2e3);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
}
</style>
