import { BaseCrudApi } from './abstract/base-crud.api';
import { Limit, Tariff } from '~/entities/tariffs';

export class TariffsApi extends BaseCrudApi {
  constructor() {
    super('tariffs');
  }

  async getByLimit(limit: string) {
    return this.api.get<Tariff[]>(`limit/${limit}`);
  }

  async getLimits(): Promise<Limit[]> {
    return this.api.get<Limit[]>('limits').then(({ data }) => data);
  }
}
