export enum ChannelsTransportType {
  whatsapp = 'whatsapp',
  wapi = 'wapi',
  instagram = 'instagram',
  tgapi = 'tgapi',
  telegram = 'telegram',
}

export enum ChannelStatus {
  init = 'init',
  onModeration = 'onModeration',
  active = 'active',
  tariffActivation = 'tariffActivation',
  tariffNotBeenPaid = 'tariffNotBeenPaid',
  error = 'error',
  closed = 'closed',
  deletedByTimeout = 'deletedByTimeout',
  unauthorized = 'unauthorized',
  banned = 'banned',
}

export enum MessageLimitTier {
  TIER_0 = 'TIER_0',
  TIER_1K = 'TIER_1K',
  TIER_10K = 'TIER_10K',
  TIER_100K = 'TIER_100K',
  TIER_UNLIMITED = 'TIER_UNLIMITED',
}

export type ChannelTier = MessageLimitTier | string;

export interface Channel extends Record<string, any> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  channelId: string;
  userId: string;
  verificationLink?: string;
  transport: ChannelsTransportType;
  status: ChannelStatus;
  isActivated: boolean;
  tier: ChannelTier;
  countMessagePerDay: string;
  subscriptionExpiredAt: Date | null;
}

export interface WzIntegrationChannel {
  name: string;
  channelId: string;
  state: string;
  transport: ChannelsTransportType;
}
