import { RouteRecordRaw } from 'vue-router';

import { lazy } from './lazy';

export const authRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: lazy(import('~/views/auth/LoginView.vue')),
    meta: {
      layout: 'AuthLayout',
      pageTitle: 'auth.enter',
    },
  },
  {
    path: '/login/confirm',
    name: 'loginConfirm',
    component: lazy(import('~/views/auth/ConfirmCodeView.vue')),
    meta: {
      layout: 'AuthLayout',
      pageTitle: 'auth.enter',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: lazy(import('~/views/auth/RegisterView.vue')),
    meta: {
      layout: 'AuthLayout',
      pageTitle: 'auth.enter',
    },
  },
  {
    path: '/register/confirm',
    name: 'registerConfirm',
    component: lazy(import('~/views/auth/ConfirmCodeView.vue')),
    meta: {
      layout: 'AuthLayout',
      pageTitle: 'auth.enter',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: lazy(import('~/views/auth/ErrorView.vue')),
    meta: {
      layout: 'AuthLayout',
      pageTitle: 'auth.enter',
    },
  },
  {
    path: '/register/after-without-wazzup',
    name: 'afterRegistrationWithoutWazzapView',
    component: lazy(import('~/views/auth/AfterRegistrationWithoutWazzapView.vue')),
    meta: {
      layout: 'AuthLayout',
      pageTitle: 'auth.enter',
    },
  }
];
