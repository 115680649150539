<script setup lang="ts">
import { ref, watch } from 'vue';

type ShowParams = {
  title: string;
  message: string;
  cancelBtn?: string;
  okBtn?: string;
  reverseBtn?: boolean;
};

const title = ref<string>('');
const message = ref<string>('');
const cancelBtn = ref<string>('Отмена');
const okBtn = ref<string>('Подтвердить');
const reverseBtn = ref<boolean>(false);
const visibleModel = ref<boolean>(false);
const resolvePromise = ref<(value: unknown) => void>();
const rejectPromise = ref<(value: unknown) => void>();

const onClose = () => {
  visibleModel.value = false;
};

const show = (payload: ShowParams) => {
  title.value = payload.title;
  message.value = payload.message;
  payload.cancelBtn && (cancelBtn.value = payload.cancelBtn);
  payload.okBtn && (okBtn.value = payload.okBtn);
  payload.reverseBtn && (reverseBtn.value = payload.reverseBtn);
  visibleModel.value = true;
  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve;
    rejectPromise.value = reject;
  });
};

const onConfirm = () => {
  resolvePromise.value!(true);
  visibleModel.value = false;
};

watch(visibleModel, (value) => {
  if (!value) resolvePromise.value!(false);
});

defineExpose({
  show,
});
</script>

<template>
  <PDialog v-model="visibleModel" width="x-small">
    <template #header>
      <header>
        <p>{{ title }}</p>
        <IconCrossMd class="icon" @click="onClose" />
      </header>
    </template>
    <div class="main">
      <p>{{ message }}</p>
    </div>
    <template #footer>
      <footer>
        <PButton :color="'secondary'" @click="[reverseBtn ? onConfirm() : onClose()]">{{ cancelBtn }}</PButton>
        <PButton @click="[reverseBtn ? onClose() : onConfirm()]">{{ okBtn }}</PButton>
      </footer>
    </template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding: 12px 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

header p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

header .icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

footer {
  box-sizing: border-box;
  padding: 8px 16px 16px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

footer * {
  width: 100%;
}

.main {
  box-sizing: border-box;
  padding: 28px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
</style>
