import { FindOptions } from '~/api';
import {
  CreateTagDto,
  Customer,
  CustomersFilter,
  CustomerUploadStatus,
  Field,
  InclusionsExclusions,
  OrderColumns,
} from '~/entities/customer';
import { CatchAll } from '~/error/catch';
import axios, { AxiosInstance } from "axios";
import { config } from "~/config";

export class CustomersApi {
  protected readonly api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: `${config.contactsApiUrl}`,
      withCredentials: true,
      validateStatus: (status) => status >= 200 && status < 500,
    });
  }

  /**
   * Установить фильтр
   * @param filters Список фильтров
   * @example
   * api.customers.filter([
   *  {
   *    comparator: 'eq',
   *    fieldId: '7da64648-72d4-4fc8-904a-ae4ff4110d31',
   *    value: 'Пупкин Иван Иванович',
   *  },
   *  {
   *    comparator: 'gt',
   *    fieldId: '7da64648-72d4-4fc8-904a-ae4ff4110d31',
   *    value: '18',
   *  },
   *  {
   *    comparator: 'find',
   *    fieldId: '7da64648-72d4-4fc8-904a-ae4ff4110d31',
   *    value: 'Иван',
   *  }
   * ])
   * @summary
   *
   * comparator: 'eq', 'gt', 'lt', 'gte', 'lte', 'find'
   */
  @CatchAll()
  async fetchMetaCustomers(filters: CustomersFilter[]) {
    return this.api
      .post<{
        customers: Customer[];
        countAll: number;
        countFiltered: number;
        listOfIds: string[];
      }>('contacts/list-with-meta', { filters })
      .then(({ data }) => data);
  }

  @CatchAll()
  async fetchCustomers(
    filters: CustomersFilter[],
    { take, skip }: FindOptions,
  ) {
    return this.api
      .post<{
        customers: Customer[];
      }>('contacts/list', { filters }, { params: { take, skip } })
      .then(({ data }) => data);
  }

  @CatchAll()
  async getNoValueFields(
    payload: { filters: CustomersFilter[] } & InclusionsExclusions,
  ) {
    return this.api.post<Field[]>('contacts/novalue', payload);
  }

  @CatchAll()
  async findUploadedWithMeta<T extends object>(uploadId: string, query?: T) {
    return this.api.get(`contacts/upload-with-meta/${uploadId}`, { params: query });
  }

  @CatchAll()
  async findUploaded(
    uploadId: string,
    query: FindOptions & { uploadStatus?: CustomerUploadStatus },
  ) {
    return this.api.get(`contacts/upload/${uploadId}`, { params: query });
  }

  @CatchAll()
  async saveUpload(uploadId: string, tags: string[]) {
    return this.api.patch(`contacts/upload/${uploadId}`, { tags });
  }

  @CatchAll()
  async saveTags(dto: CreateTagDto) {
    return this.api.post('tags', dto);
  }

  @CatchAll()
  async getTags() {
    return this.api.get('tags');
  }

  @CatchAll()
  async removeUpload(uploadId: string) {
    return this.api.delete(`contacts/upload/${uploadId}`);
  }

  @CatchAll()
  async removeFromUpload(uploadId: string, customerId: string) {
    return this.api.delete(`contacts/from-upload/${uploadId}/${customerId}`);
  }

  @CatchAll()
  async(uploadId: string) {
    return this.api.delete(`contacts/upload/${uploadId}`);
  }

  @CatchAll()
  async replaceField(
    fieldId: string,
    newField: Partial<Field>,
    uploadId: string,
  ) {
    return this.api.put('fields', {
      fieldId,
      newField,
      uploadId,
    });
  }

  @CatchAll()
  async updateFieldValue(payload: {
    updates: {
      id?: string;
      value: string;
      customerId: string;
      fieldId: string;
    }[];
    uploadId: string;
  }) {
    return this.api.put('field-values', payload);
  }

  @CatchAll()
  async removeFieldFromUpload(fieldId: string, uploadId: string) {
    return this.api.delete(`fields/${fieldId}/${uploadId}`);
  }

  @CatchAll()
  async uploadClipboard(customers: string, uploadId: string) {
    return this.api.post('contacts/clipboard-upload', { customers, uploadId });
  }

  @CatchAll()
  async getMetadata(company_id: string) {
    return this.api.get(`contacts/metadata/${company_id}`);

  }

  @CatchAll()
  async createMetadata(company_id: string, order_columns: OrderColumns) {
    return this.api.post('contacts/metadata', { company_id, columnsValue: order_columns });
  }

  @CatchAll()
  async updateMetadata(company_id: string, order_columns: OrderColumns) {
    return this.api.patch(`contacts/metadata/${company_id}`, { order_columns: order_columns });
  }
}
