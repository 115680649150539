import { useToast } from "@profeat/ui-kit";
import { defineStore } from "pinia";
import type { Graph, IEditorState, IGraphState, NodeInterface } from "baklavajs";
import { v4 as uuidv4 } from 'uuid';
import { api } from "~/api";
import {
  Bot,
  BotMode,
  BotRequestBody,
  BotRunTestDto,
  BotStatistics,
  BotStatisticsResponse,
  BotStatus,
  BotTypes,
  Chatbot,
  ChatbotRequestBody,
  ChatbotsStatus,
  ChatbotStep,
  ChatBotStepType,
} from "~/entities/chatbots";
import { useTemplates } from "~/stores/template.store";
import { useUser } from "~/stores/user.store";
import { $t } from "~/i18n";
import { ChannelsTransportType } from '~/entities/channel';

// export type ChatBotStartType = "incoming" | "outgoing" | "telegram";

export enum ChatBotStartType {
  incoming = "incoming",
  outgoing = "outgoing",
  telegram = "telegram",
}

export type ConstructorType= "waba" | "telegram";

type ChatBotType = Chatbot & {
  start: ChatBotStartType | null;
  templateText: string | null;
  templateId?:  string | undefined;
};
type Chatbots = Array<ChatBotType>;

type BotType = Bot & {
  start: ChatBotStartType | null;
  templateText: string | null;
};

export interface ChatBotTestingDTO {
  chatBotId: string;
  phone?: string;
  schema?: string
}

interface IConnection {
  stepFromId: string;
  stepToId: string;
  outputKey: string;
  // ID невидимого-теневого STEP'а который связывает между собой два шага
  shadowStepId: string;
}

interface ISchema {
  steps: ChatbotStep[];
  connections: IConnection[];
}

interface State {
  chatbots: Chatbots;
  templateChatBots: {
    [templateId: string]: { chatBotId: string; chatBotName: string }[];
  };
  workingDays: string[];
  isFetching: boolean;
  notClosedConnections: string[];
  isIntegrity: boolean;
  showTestModal: boolean;
  testingPhone: string;
  isLoadingFlagForTestModal: boolean;
  templateStartType: boolean;
  templateButtonVariables: Record<string, Record<string, string>>;
  stats: boolean;
  hasStats: boolean;
  botStatistics: BotStatistics;
  schema: ISchema | undefined;
  connections: IConnection[] | undefined;
  status: BotStatus;
  botMode: BotMode,
  botsData: Bot[];
  botData: Bot;
  botSchema: IGraphState;
  isActivateInstantly: boolean;
  alertNodeIds:  Set<string>;
  constructorType: ConstructorType;
  DataModalActivationConflict: {
    visible: boolean,
    message: string
  };
}

export const useChatbots = defineStore("chatbots", {
  state: (): State => ({
    chatbots: [],
    templateChatBots: {},
    workingDays: [],
    isFetching: false,
    notClosedConnections: [],
    isIntegrity: true,
    showTestModal: false,
    testingPhone: '',
    isLoadingFlagForTestModal: true,
    templateStartType: false,
    templateButtonVariables: {},
    stats: false,
    hasStats: false,
    botStatistics: {} as BotStatistics,
    schema: undefined,
    connections: undefined,
    status: BotStatus.Draft,
    botMode: BotMode.RELEASE,
    botsData: [] as Bot[],
    botData: {} as Bot,
    botSchema: {} as IGraphState,
    isActivateInstantly: false,
    alertNodeIds: new Set,
    constructorType: 'telegram',
    DataModalActivationConflict: {
      visible: false,
      message: '',
    },
  }),

  actions: {
    setTemplateStartType(val: boolean) {
      this.templateStartType = val;
    },
    setStats(value: boolean) {
      this.stats = value;
    },
    setBotsData(value: Bot[]) {
      this.botsData = value;
    },
    setBotData(value: Bot) {
      this.botData = value;
    },
    setHasStats(payload: boolean) {
      this.hasStats = payload;
    },
    setStatus(payload: BotStatus) {
      this.status = payload;
    },
    setBotMode(payload: BotMode){
      this.botMode = payload;
    },
    setTestingPhone(payload: string) {
      this.testingPhone = payload;
    },
    setAlertNodeIds(payload:  Set<string>){
      this.alertNodeIds = payload;
    },
    setConstructorType(payload: ConstructorType){
      this.constructorType = payload;
    },
    setDataModalActivationConflict(payload: {
      visible: boolean,
      message: string
    }){
      this.DataModalActivationConflict = payload;
    },

    async createBot(body: BotRequestBody) {
      const { data } = await api.bot.createBot(body);
      return data as Bot;
    },

    async getBots(skip: string, take: string) {
      const templateStore = useTemplates();

      await templateStore.fetch();
      const { data } = await api.bot.getBots(take, skip);
      this.botsData = (data as Bot[])
      .map((bot) => {
        if(bot.type === 'TELEGRAM'){
          return {
            ...bot,
            startType: 'telegram' as ChatBotStartType
          }
        }
        const cuurentBotSnap: IGraphState = bot.botSnapshots.find(snapshot => snapshot.isCurrent)?.schema as IGraphState
        if(!(cuurentBotSnap?.nodes?.[0]?.type === "WabaStartNode")){
          return {
            ...bot,
            templateText: null,
            startType: null
          }
        }
        const value: string = cuurentBotSnap?.nodes?.[0].outputs?.tabs?.value as unknown as string
        if(value === ChatBotStartType.incoming) {
          return {
            ...bot,
            templateText: null,
            startType: 'incoming' as ChatBotStartType
          }
        } else {
          const templateStore = useTemplates();
          const template = templateStore.getTemplatesMap[cuurentBotSnap.nodes[0].outputs?.selectTemplate.value as unknown as string] ||
            null;

          return {
            ...bot,
            startType: 'outgoing' as ChatBotStartType,
            templateText: template?.title,
            templateId:  template?.id,
          }
        }

      })
      .sort((a, b) => {
        if (a.status === 'ACTIVE') return -1;
        if (b.status === 'ACTIVE') return 1;

        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
    },

    async getOneBot(id: string) {
      const { data } = await api.bot.getOneBot(id);
      this.botData = data as Bot
      this.setStatus(data.status as BotStatus)
      if (data.botSnapshots){
        this.botSchema = data.botSnapshots[0].schema as IGraphState
      }

      return data as Bot;
    },

    async updateBot(id: string, body: BotRequestBody, mode: BotMode, confirm: boolean = false) {
      const userStore = useUser();
      await api.bot.updateSchema({graph: JSON.stringify(body.schema), botId: this.botData.id})
      const { data } = await api.bot.updateBot(id, body, userStore.user.id, confirm, mode)
      if(data.error && data.error.statusCode == 409){
        this.setDataModalActivationConflict({
          visible: true,
          message: data.error.message,
        })
        return null
      }
      this.setBotData(data)
      return data
    },

    async putchBot(id: string, body: {
      status?: string,
      name?: string,
      channelId?: string,
      confirm: boolean,
      mode?: BotMode,
    }) {
      const { data } = await api.bot.putchBot(id, body);

      console.log('🦕 data', data)

      return data as Bot;
    },

    async deleteBot(id: string) {
      const { data } = await api.bot.deleteBot(id);
      return data as Bot;
    },

    async testBotRun(body: BotRunTestDto){
      const { data } = await api.bot.testBotRun(body)
      return data
    },

    async createNewChatBot(body: ChatbotRequestBody) {
      const { data } = await api.chatbots.createChatBot(body);
      return data as Chatbot;
    },

    async updateWorkingDays(days: string[]) {
      this.workingDays = days;
    },

    async fetchBotsByTemplate() {
      await this.getBots('0', '100');
      const templateChatBots:{
        [templateId: string]: { chatBotId: string; chatBotName: string }[];
      } = {}
      this.botsData
      .filter(bot =>
        bot.type === BotTypes.waba &&
        (bot.status === BotStatus.Active) &&
        bot.startType === 'outgoing' as ChatBotStartType
      )
      .forEach((bot) => {
        const currentSchema: IGraphState = bot.botSnapshots.find(botSnapshot => botSnapshot.isCurrent)?.schema as IGraphState
        const templateId: string = currentSchema.nodes[0].outputs.selectTemplate.value as unknown as string
        if(templateId !== ''){
          if(templateChatBots[templateId]){
            templateChatBots[templateId].push({
              chatBotId: bot.id,
              chatBotName: bot.name
            })
          } else {
            templateChatBots[templateId] = []
            templateChatBots[templateId].push({
              chatBotId: bot.id,
              chatBotName: bot.name
            })
          }
        }

      })
      this.templateChatBots = templateChatBots
    },

    async sendTestBroadcast(phoneNumber: string) {
      const { showToast } = useToast();
      try {
        await api.chatbots.sendTestingBroadcast(
          this.getCurrent.id,
          phoneNumber.replace("+", "")
        );
        showToast({
          text: "Рассылка отправлена успешно",
          color: "success",
        });
      } catch (err) {
        showToast({
          text: "Не получилось отправить рассылку",
          color: "error",
        });
      }
    },

    async deleteTgBot(id: string) {
      const { showToast } = useToast();
      await this.deleteBot(id)
      await this.getBots('0', '100')

      showToast({
        text: $t("chatbots.chatBotRemoved"),
        color: "default",
      });
    },

    setFetching(payload: boolean) {
      this.isFetching = payload;
    },

    async disableActiveBot(id: string) {
      await api.bot.putchBot(id, {status: BotStatus.Draft, confirm: false})

      const updateIndex = this.botsData.findIndex(
        ({ id: chatbotId }) => chatbotId === id
      );

      if (updateIndex !== -1) {
        const updated = {
          ...this.botsData[updateIndex],
          status: BotStatus.Draft,
        };
        const newBots = this.botsData.splice(updateIndex, 1, updated);
        this.setBotsData(newBots)
      }
    },

    async fetchbotStatistics() {
      if (!this.botData.id) return;
      const { data } = await api.bot.getStatistics(
        this.botData.id
      );

      const dataStatistic:BotStatistics = {
        uniqueCustomers: data.uniqueCustomers,
        connectionsCount: {},
        stateUniqueCustomers: {},

      }

      if(data.connectionsCount.length){
        const mapConnections:Record<string, string> = {}
        // В самом node нет возможности узнать id connection, поэтому подменяем его id from, от куда выходит связь
        this.botSchema.connections.forEach(connection => {
          mapConnections[connection.id] = connection.from
        })
        // Создаем Map связей из массива
        data.connectionsCount.forEach(data => {
          dataStatistic.connectionsCount[mapConnections[data.connection_id]] = data.connections_count
        })
      }
      // Создаем Map уникальных узеров из массива
      if(data.stateUniqueCustomers.length){
        data.stateUniqueCustomers.forEach(data => {
          dataStatistic.stateUniqueCustomers[data.state_id] = data.unique_customers
        })
      }

      this.botStatistics = dataStatistic
    },

    async resetBotStats(schema: IGraphState) {
      //  сохраняем схему, из-за этого статистика на бэке сбросится

      await this.updateBot(this.botData.id, {
          channelId: this.botData.channelId,
          name: this.botData.name,
          status: BotStatus.Draft,
          type: this.botData.type,
          schema: schema
        },
        BotMode.RELEASE
      )


      this.botStatistics = {
        uniqueCustomers: {
          total_count: ''
        },
        connectionsCount: {},
        stateUniqueCustomers: {},

      }

    },

    setShowTestModal(payload: boolean) {
      this.showTestModal = payload;
    },

    setIsLoadingFlagForTestModal(flag: boolean) {
      this.isLoadingFlagForTestModal = flag;
    },

    setIsIntegrity(flag: boolean) {
      this.isIntegrity = flag;
    },



    detectTypeStartStep(payload: ChatbotStep): {
      start: ChatBotStartType | null;
      templateText: string | null;
    } {
      const templateStore = useTemplates();
      const result: {
        start: ChatBotStartType | null;
        templateText: string | null;
      } = {
        start: null,
        templateText: null,
      };

      if (payload.type === ChatBotStepType.start) {
        if (Object.values(payload.function.send).length) {
          result.start = ChatBotStartType.outgoing;
          result.templateText =
            templateStore.getTemplatesMap[payload.function.send?.text]?.title ||
            null;
        } else {
          result.start = ChatBotStartType.incoming;
        }
      }

      return result;
    },

    async disableTestingBot() {
      const id = this.botData?.id;

      if (id) {
        await api.bot.putchBot(id, {status: BotStatus.Draft, confirm: false, mode: BotMode.RELEASE})

        const newBot = this.botData
        newBot.status = BotStatus.Draft
        this.setStatus(BotStatus.Draft)
        this.setBotMode(BotMode.RELEASE)
        this.setTestingPhone('')
        this.setBotData(newBot)
      }
    },
  },

  getters: {
    getStats: (state: State) => state.stats,
    getChatbots: (state: State) => state.botsData,
    getWorkingDays: (state: State) => state.workingDays,
    getActiveChatbots: (state: State) => state.botsData.filter((item) =>
        [BotStatus.Active].includes(item.status.toLowerCase() as BotStatus)
      ),
    getActiveChatbotsForTrigersMailing: (state: State) => state.botsData.filter((item) =>
      [BotStatus.Active].includes(item.status as BotStatus)
      && item.startType == "outgoing"
    ),
    getDisabledChatbots: (state: State) =>
      state.botsData.filter((item) =>
        [BotStatus.Draft].includes(item.status)
      ),
    getTemplateChatbots: (state: State) => state.templateChatBots,
    getFetching: (state: State) => state.isFetching,
    getCurrent: (state: State) => state.botData,
    hasChatbotDisabled: () => {
      const userStore = useUser();
      const tariff = userStore.connectedTariff;

      return !tariff || !tariff.hasChatBot;
    },
    isTemplateStart: (state: State) => state.templateStartType,
    showModal: (state: State) => state.showTestModal,
    getIsLoadingFlagForTestModal: (state: State) =>
      state.isLoadingFlagForTestModal,
    getIsIntegrity: (state: State) => state.isIntegrity,
    getIncomingActiveBots: (state: State) => {
      return state.botsData.filter(({ startType, channel, status }) => {
        return (
          startType === 'incoming' &&
          channel!.transport === ChannelsTransportType.wapi &&
          status === BotStatus.Active
        );
      });
    },
  },
});
