import { RouteLocation } from "vue-router";
import { useUser } from "~/stores/user.store";

export const integrationUserGuard = (to: RouteLocation) => {
  const userStore = useUser();

  if (userStore.isWzIntegration) {
    if (to.fullPath.includes("chats") || to.fullPath.includes("templates")) {
        return { name: "integration-error" };
    }
  }
};
