import { config } from '~/config';
import axios, { type AxiosInstance } from 'axios';

export abstract class BaseApi {
  protected readonly api: AxiosInstance;

  constructor(basePath = '') {
    this.api = axios.create({
      baseURL: `${config.apiUrl}/${basePath}`,
      withCredentials: true,
      validateStatus: (status) => status >= 200 && status < 500,
    });
  }
}
