<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useVModel } from '@vueuse/core';
import { IconArrowLeftMd, IconCrossMd } from '@profeat/ui-kit';

import { useChannels } from '~/stores/channel.store';

const channelStore = useChannels();

const props = defineProps<{ modelValue: boolean }>();
const emits = defineEmits<{
  (event: 'update:modelValue', payload: boolean): void;
  (event: 'back'): void;
  (event: 'add', token: string): void;
}>();

const visibleModel = useVModel(props, 'modelValue', emits);
const onClose = () => {
  emits('update:modelValue', false);
};

const isDisabled = computed(() => {
  if (!token.value.length) return true;
  if (channelStore.telegramError.length) return true;

  return false;
});

const onAddChannel = () => {
  emits('add', token.value);
};

const onBack = () => {
  emits('back');
  onClose();
};
const toTgHelp = () => {
  window.open('https://sendbot.yourgood.ru/help/telegram-bot', '_blank');
};

// Данные токена
const token = ref<string>('');

watch(token, () => channelStore.setTelegramError(''));
</script>

<template>
  <PDialog v-model="visibleModel" width="small">
    <template #header>
      <header>
        <IconArrowLeftMd class="icon" @click="onBack" />
        <p>{{ $t('profile.modals.addTgChannel.title') }}</p>
        <IconCrossMd class="icon" @click="onClose" />
      </header>
    </template>
    <div class="main">
      <div class="content">
        <PInput
          v-model="token"
          :label="$t('profile.modals.addTgChannel.input.label')"
          :placeholder="'6700000000: AAАААААq99_gteyRKmcN-HjRoa3Hkyui'"
          :error="channelStore.telegramError"
          :disabled="false"
          :size="'large'"
          :text-size="'medium'"
          :hint="''"
          :type="'text'"
          :autocomplete="'off'"
          :autofocus="true"
          :readonly="false"
          :lost-focus-on-enter="false"
          :input-mode="'text'"
          :border="'default'"
          :mask="''"
        />
      </div>
    </div>
    <template #footer>
      <footer>
        <PButton
          @click="toTgHelp"
          color="secondary"
          :disabled="channelStore.getFetching"
        >
          {{ $t('profile.modals.addTgChannel.question') }}
        </PButton>
        <PButton
          @click="onAddChannel"
          :loading="channelStore.getFetching"
          :disabled="isDisabled"
        >
          {{ $t('profile.modals.addTgChannel.btn') }}
        </PButton>
      </footer>
    </template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 12px 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

header p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

header .icon {
  cursor: pointer;
}

.main {
  box-sizing: border-box;
  padding: 24px 16px;
}

footer {
  box-sizing: border-box;
  padding: 0px 16px 16px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

footer * {
  width: 100%;
}
</style>
