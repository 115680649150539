import {
  TriggerBroadcast,
  TriggerBroadcastQuery,
} from "~/entities/trigger-broadcast";
import { BaseCrudApi } from "./abstract/base-crud.api";
import { FindOptions } from ".";

export class TriggerBroadcastApi extends BaseCrudApi {
  constructor() {
    super("trigger-broadcast");
  }

  getAll(query?: FindOptions): Promise<{ data: TriggerBroadcast[] }> {
    return this.api.get("", { params: query });
  }

  setKey(id: string, key: string): Promise<{ data: TriggerBroadcastQuery }> {
    return this.api.post(`/query/${id}`, { key });
  }

  getStatistics() {
    return this.api.get("/statistics");
  }

  updateFile(id: string, file: File) {
    const formData = new FormData();
    formData.append("headerFile", file);

    const headers = { "Content-Type": "multipart/form-data" };
    return this.api.patch(`${id}`, formData, { headers });
  }

  preview(id: string, phone: string) {
    return this.api.post(`preview/${id}`, { phone });
  }

  getDelayedJobsCount(
    triggerBroadcastId: string
  ): Promise<{ data: { count: number } }> {
    return this.api.get(`jobs/${triggerBroadcastId}`);
  }

  useJob(triggerBroadcastId: string, send: boolean) {
    return this.api.post(`jobs/${triggerBroadcastId}`, { send });
  }
}
