import { Channel } from '~/entities/channel';
import type { Graph, IGraphState } from 'baklavajs';
import { ChatBotStartType } from '~/stores/chatbots.store';

export enum ChatbotsStatus {
  active = 'active',
  ready = 'ready',
  draft = 'draft',
  test = 'test',
}

export enum BotStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
}

export enum BotTypes {
  telegram = 'TELEGRAM',
  waba = 'WHATSAPP'
}

export enum ChatBotStepType {
  // шаг-условие
  step = 'step',
  // действие
  action = 'action',
  // timeout step
  timeout = 'timeout',
  // start step
  start = 'start',
  // шаг отправка вебхука во вне
  webhook = "webhook",
  // шаг остановки бота
  end = 'end',
}

export interface BotStatistics {
  uniqueCustomers: {
    total_count: string;
  }
  stateUniqueCustomers: {
    [state_id: string]:  string;
  }
  connectionsCount: {
    [connection_id: string]:  string;
  }
}

export interface BotStatisticsResponse {
  uniqueCustomers: {
    total_count: string;
  }
  stateUniqueCustomers: {
    state_id: string;
    unique_customers: string;
  }[]
  connectionsCount: {
    connection_id: string;
    connections_count: string;
  }[]
}

export interface BotRequestBody {
  type: string,
  status: string,
  name: string,
  channelId: string,
  schema: IGraphState
}

export interface BotTestRunDto {
  id: string,
  chatId: string, // номер телефона
}

export enum BotMode {
  RELEASE = 'RELEASE',
  TEST = 'TEST',
}

export interface Bot {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  userId: string,
  type: string,
  status: BotStatus,
  name: string,
  channelId: string,
  mode: BotMode,
  companyId: string | null,
  startType: ChatBotStartType | null,
  templateText: string | null,
  templateId?:  string | undefined;
  botSnapshots: botSchema[],
  channel?: Channel,
}

export interface botSchema {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  botId: string,
  isCurrent: true,
  schema: IGraphState | unknown
}

export interface ChatbotRequestBody {
  name: string;
  priority: number;
  companyId: string;
  channelId: string;
}

export interface Chatbot extends Record<string, any> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  status: BotStatus;
  name: string;
  priority: number;
  channelId: string;

  connections?: ChatbotConnection[];
  steps?: ChatbotStep[];
  channel?: Channel;
}

export interface ChatbotStep {
  id: string;
  chatBotId: string;
  createdAt: Date;
  updatedAt: Date;
  function: any;
  type: ChatBotStepType;
  coords: any;
  state: any;
}

export interface ChatbotConnection {
  id: string;
  chatBotId: string;
  createdAt: Date;
  updatedAt: Date;
  outputKey: string | null;
  stepFromId: string | null;
  stepToId: string | null;

  stepTo?: ChatbotStep;
  stepFrom?: ChatbotStep;
}
export interface BotRunTestDto {
  id: string;
  chatId: string;
}