import { CustomersFilter, TypeComparators } from '~/entities/customer';

export const EMPTY_DATE = 'EMPTY_DATE_VALUE';

export class FilterBuilder {
  private _filter: CustomersFilter[] = [];

  get filter() {
    return this._filter;
  }

  /**
   * Возвращает элемент с выбранным идентификатором
   * @param fieldId Идентификатор поля
   */
  getFilterById(fieldId: string) {
    return this._filter.find((f) => f.fieldId === fieldId)?.value ?? null;
  }

  /**
   * Возвращает массив фильтров с выбранным идентификатором
   * @param fieldId Идентификатор поля
   */
  getFiltersById(fieldId: string) {
    return this._filter.filter((f) => f.fieldId === fieldId);
  }

  /**
   * Добавить фильтр
   * @param filter Фильтр
   */
  append(filter: CustomersFilter) {
    this._filter = this._filter.filter((f) => f.comparator != filter.comparator || f.fieldId != filter.fieldId);
    this._filter.push(filter);
    return this;
  }

  /**
   * Удалить фильтр для поля
   * @param fieldId Идентификатор поля
   */
  remove(fieldId: string) {
    this._filter = this._filter.filter((f) => f.fieldId !== fieldId);
  }

  /**
   * Очистить фильтр
   */
  clear() {
    this._filter = [];
  }

  /**
   * Значение поля равно контрольному значению
   * @param fieldId Идентификатор поля
   * @param value Контрольное значение
   */
  eq(fieldId: string, value: string) {
    return this.append({
      fieldId,
      value,
      comparator: 'eq',
    });
  }

  /**
   * Значение поля больше контрольного значения
   * @param fieldId Идентификатор поля
   * @param value Контрольное значение
   */
  gt(fieldId: string, value: string) {
    return this.append({
      fieldId,
      value,
      comparator: 'gt',
    });
  }

  /**
   * Значение поля меньше контрольного значения
   * @param fieldId Идентификатор поля
   * @param value Контрольное значение
   */
  lt(fieldId: string, value: string) {
    return this.append({
      fieldId,
      value,
      comparator: 'lt',
    });
  }

  /**
   * Значение поля больше либо равно контрольному значению
   * @param fieldId Идентификатор поля
   * @param value Контрольное значение
   */
  gte(fieldId: string, value: string) {
    return this.append({
      fieldId,
      value,
      comparator: 'gte',
    });
  }

  /**
   * Значение поля меньше либо равно контрольному значению
   * @param fieldId Идентификатор поля
   * @param value Контрольное значение
   */
  lte(fieldId: string, value: string) {
    return this.append({
      fieldId,
      value,
      comparator: 'lte',
    });
  }

  /**
   * Значение поля находится между минимальным и максимальным значениями
   * @param fieldId Идентификатор поля
   * @param min Минимальное значение
   * @param max Максимальное значение
   */
  between(fieldId: string, min: string, max: string) {
    return this.gte(fieldId, min).lte(fieldId, max);
  }

  /**
   * Значение поля содержит контрольное значение
   * @param fieldId Идентификатор поля
   * @param value Контрольное значение
   */
  find(fieldId: string, value: string) {
    return this.append({
      fieldId,
      value,
      comparator: 'find',
    });
  }

  /**
   * Значение поля содержит одно из контрольных значений из массива
   * @param fieldName вид фильтра status | uploadMethod
   * @param fieldId Идентификатор поля
   * @param value Массив значении
   */
  type(fieldName: TypeComparators, fieldId: string, value: string[]) {
    return this.append({
      fieldId,
      value,
      comparator: fieldName,
    });
  }

  /**
   * Значение поля содержит одно из контрольных значений из массива
   * @param fieldId Идентификатор поля
   * @param value Массив значении
   */
  tag(fieldId: string, value: string[]) {
    return this.append({
      fieldId,
      value,
      comparator: 'tag',
    });
  }
}
