<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { config } from '~/config';
import { Head } from '@vueuse/head';

const route = useRoute();
const { t } = useI18n();

const title = computed(() => {
  const pageTitle = route.meta.pageTitle ? t(route.meta.pageTitle) : '';
  return t('hd.title', { pageTitle });
});

const description = computed(() => t('hd.description'));

const url = computed(() => `${config.host}${route.path}`);
</script>

<template>
  <Head>
    <title>{{ title }}</title>
    <meta property="description" :content="description" />
    <meta property="og:url" :content="url" />
    <meta property="og:title" :content="title" />
    <meta property="og:description" :content="description" />
    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://static.tildacdn.com/tild6663-3561-4564-b164-303062333462/1600900.jpg"
    />
  </Head>
</template>
