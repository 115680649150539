import { BaseApi } from './abstract/base.api';

export class ProfileApi extends BaseApi {
  constructor() {
    super();
  }

  async getEditProfileIframeLink(channelId: string): Promise<{ link: string }> {
    return this.api.post('wazzup/edit-profile-iframe', { channelId }).then(({ data }) => data);
  }

  async updateWzApiKey(key: string): Promise<any> {
    return this.api.patch('wazzup', { key }).then(({ data }) => data)
  }
}
