import { BaseCrudApi } from './abstract/base-crud.api';
import { CreateGiftResponse, Subscription } from '~/entities/subscriptions';

interface RenewBody {
  subscriptionId: string;
  returnUrl: string;
  withAutoRenew: boolean;
}

export interface UpdateRenewalBody {
  chainId: string;
  isRenew: boolean;
}

export class SubscriptionsApi extends BaseCrudApi {
  constructor() {
    super('subscriptions')
  }

  async createGift() {
    return this.api.post<CreateGiftResponse>('gift');
  }

  async activateGift() {
    return this.api.patch<{ subscription: Subscription }>('gift');
  }

  async renew(body: RenewBody) {
    return this.api.post<{ confirmationUrl: string }>('renew', body).then(({ data }) => data);
  }

  async updateRenewal(body: UpdateRenewalBody) {
    return this.api.post('update-renewal', body).then(({ data }) => data);
  }
}
