import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import { config } from '~/config';

export default ({
  app,
  router,
}: {
app: App<Element>;
router: Router;
}): void => {
  const isProd = config.nodeEnv === 'production';

  if (!isProd) {
    return;
  }

  setTimeout(() => {
    try {
      Sentry.init({
        app,
        dsn: config.sentryDsn,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: [config.host, /^\//],
          }),
        ],
        tracesSampleRate: 1.0,
      });
    } catch (e) {
      console.error('sentry inject error: ', e);
    }
  }, 2000);
};
