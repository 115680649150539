<script setup lang="ts">
import { ref, computed } from 'vue';
import { useChannels } from '~/stores/channel.store';

const channelsStore = useChannels();

const emptyChannelList = ref<boolean>(true);

const isWzChannelMultiple = computed(() => !channelsStore.channels.length && channelsStore.wzChannels.length > 1);

const isNoChannels = computed(() => !channelsStore.channels.length && !channelsStore.wzChannels.length);

const onRedirectWazzup = () => {
  window.location.href = 'https://wazzup24.com';
};
</script>
<template>
  <div class="profile-channel">
    <div class="profile-channel__header">
      <div class="profile-channel__header-content">
        <h4 class="profile-channel__title">Каналы</h4>
      </div>
    </div>
    <ProfileWzChannelMultiple v-if="isWzChannelMultiple" />
    <PAlert
      v-else-if="isNoChannels"
      v-model="emptyChannelList"
      :show-cross="false"
      :hide-left-icon="false"
      color="warning"
      :button-text="'Перейти в Wazzup'"
      @click:button="onRedirectWazzup"
    >
      {{ $t('profile.alert.wzChannelListEmpty') }}
    </PAlert>
    <ProfileChannelList v-else />
  </div>
</template>

<style scoped>
.profile-channel {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
  gap: 24px;
}

.profile-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-channel__header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-channel__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}
</style>
