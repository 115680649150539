import { RouteLocation } from 'vue-router';

import { useChatbots } from '~/stores/chatbots.store';

export const chatbotGuard = (to: RouteLocation) => {
  if (to.fullPath.includes('chat-bots')) {
    const chatbotsStore = useChatbots();

    if (chatbotsStore.hasChatbotDisabled) {
      return { name: 'profile' };
    }
  }
};
