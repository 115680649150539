<script setup lang="ts">
import ProfileModalConfirm from '~/components/profileV2/modal/ProfileModalConfirm.vue';
import ProfileModalEditChannel from '~/components/profileV2/modal/ProfileModalEditChannel.vue';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useChannels } from '~/stores/channel.store';
import { NotificationType, useNotification } from '~/stores/notification.store';
import { ChannelStatus, ChannelsTransportType } from '~/entities/channel';
import { useProfile } from '~/stores/profile.store';

const channelsStore = useChannels();
const { pushNotification } = useNotification();
const { t } = useI18n();
const profileStore = useProfile();

const { getChannels } = storeToRefs(channelsStore);
const modalConfirmation = ref<InstanceType<typeof ProfileModalConfirm>>();
const modalEditChannel = ref<InstanceType<typeof ProfileModalEditChannel>>();

const channelList = computed(() =>
  getChannels.value.filter(
    (channel) =>
      channel.id === channelsStore.currentChannel.id ||
      channel.transport === ChannelsTransportType.tgapi,
  ),
);

const onDelete = async (channelId: string) => {
  const ok = await modalConfirmation.value?.show({
    title: t('profile.modals.removeChannelConfirm.title'),
    message: t('profile.modals.removeChannelConfirm.description'),
    okBtn: t('profile.modals.removeChannelConfirm.remove'),
  });
  if (ok) {
    const isSuccess = await channelsStore.removeChannelById(channelId);
    if (isSuccess) {
      pushNotification({
        message: t('profile.channel.channelDelete'),
        type: NotificationType.default,
      });
    }
  }
};

const onEdit = async (channelId: string) => {
  const link = await profileStore.fetchEditIFrame(channelId);
  modalEditChannel.value?.show(link);
};
</script>

<template>
  <ProfileModalConfirm ref="modalConfirmation" />
  <ProfileModalEditChannel ref="modalEditChannel" />
  <ul class="profile-channel__list" v-if="channelList.length">
    <ProfileChannelListItem
      v-for="(channel, index) of channelList"
      :key="channel.id"
      :channel="channel"
      :divider="index + 1 !== channelList.length"
      @delete="onDelete"
      @edit="onEdit"
    />
  </ul>
</template>

<style scoped></style>
