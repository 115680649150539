import { defineStore } from 'pinia';

export enum NotificationType {
  default = 'default',
  error = 'error',
  info = 'info',
  warning = 'warning',
  success = 'success',
}

export interface Notification {
  id: string;
  message: string;
  type: NotificationType;
  translate?: boolean;
  width?: number;
}

interface State {
  notifications: Notification[];
  offset: number;
}

export const useNotification = defineStore('notification', {
  state: (): State => ({
    notifications: [],
    offset: 0,
  }),

  actions: {
    pushNotification(payload: {
      message: string;
      type: NotificationType;
      duration?: number;
      translate?: boolean;
      width?: number;
    }) {
      const id = (Math.random().toString(36) + Date.now().toString(36)).substring(2);

      this.notifications.push({
        id,
        message: payload.message,
        type: payload.type,
        translate: payload.translate,
        width: payload.width,
      });

      setTimeout(() => {
        this.removeNotification(id);
      }, payload.duration || 15000);
    },

    removeNotification(id: string) {
      this.notifications = this.notifications.filter((n) => n.id !== id);
    },

    setOffset(offset: number) {
      this.offset = offset;
    },
  },

  getters: {
    getNotifications: (state: State) => state.notifications,
  },
});
