<script setup lang="ts">
import { computed } from 'vue';
import { useSubscription } from '~/composables/useSubscription';
import { useChannels } from '~/stores/channel.store';
import { useCompany } from '~/stores/company.store';
import { useProfile } from '~/stores/profile.store';
import { useUser } from '~/stores/user.store';

const userStore = useUser();
const profileStore = useProfile();
const channelsStore = useChannels();
const companyStore = useCompany();

// Тариф фри и закончился
const isExpitedNotRenew = computed(() => {
  return userStore.connectedTariff?.isGift && profileStore.hasTariffExpired;
});

// Срок действия тарифа закончился
const isTariffExpired = computed(() => profileStore.hasTariffExpired);

// Тариф скоро закончится
const isTariffExpires = computed(() => {
  return !userStore.connectedTariff?.isGift && profileStore.isTariffWillEnd;
});

// На балансе недостаточно средств
const isBalanceLow = computed(() => !companyStore.balance && channelsStore.channels.length !== 0);

// Активация тарифа в процессе
const { subscriptionPending } = useSubscription();
</script>

<template>
  <div class="profile-alerts">
    <PAlert
      v-if="isExpitedNotRenew"
      v-model="isExpitedNotRenew"
      :show-cross="false"
      :hide-left-icon="false"
      color="error"
      :button-text="''"
    >
      {{ $t('profile.alert.isExpitedNotRenew') }}
    </PAlert>
    <PAlert
      v-else-if="isTariffExpired"
      v-model="isTariffExpired"
      :show-cross="false"
      :hide-left-icon="false"
      color="error"
      :button-text="''"
    >
      {{ $t('profile.alert.isExpited') }}
    </PAlert>
    <PAlert
      v-else-if="isBalanceLow"
      v-model="isBalanceLow"
      :show-cross="false"
      :hide-left-icon="false"
      color="warning"
      :button-text="''"
      >{{ $t('profile.alert.isBalanceLow') }}</PAlert
    >
    <PAlert
      v-else-if="subscriptionPending"
      v-model="subscriptionPending"
      :show-cross="false"
      :hide-left-icon="false"
      color="warning"
      :button-text="''"
    >
      {{ $t('profile.alert.subscriptionPending') }}
    </PAlert>
    <PAlert
      v-else-if="isTariffExpires"
      v-model="isTariffExpires"
      :show-cross="false"
      :hide-left-icon="false"
      color="info"
      :button-text="''"
    >
      {{ $t('profile.alert.tariffIsExpires') }}
    </PAlert>
  </div>
</template>

<style scoped>
.profile-alerts {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
</style>
