import { BaseApi } from './abstract/base.api';
import { AuthCodeDto } from './dto/auth-code.dto';
import { LoginByToken, PhoneDto } from './dto/phone.dto';
import { RegisterSendDto, RegisterVerifyDto } from '~/api/dto/register-send.dto';
import timezones from '~/assets/timezones.json';
import { CatchAll } from '~/error/catch';

export class AuthApi extends BaseApi {
  constructor() {
    super('auth');
  }

  isUserExists(dto: PhoneDto) {
    return this.api.post('exists', dto);
  }

  async isWzUserExists(token: string): Promise<{ isExists: boolean }> {
    return this.api
      .post('wz-exists', { token })
      .then(({ data }) => data);
  }

  async isUserExistsByWzApi(token: string) {
    return this.api
      .post('user-exists-by-wz-api', { token })
      .then(({ data }) => data);
  }

  sendLoginCode(dto: PhoneDto) {
    return this.api.post('login-send', dto);
  }

  sendRegisterCode(dto: PhoneDto) {
    return this.api.post('register-send', dto);
  }

  loginVerify(dto: AuthCodeDto) {
    return this.api.post('login-verify', {
      ...dto,
      timezone: getTimezoneOffset(),
    });
  }


  @CatchAll()
  async loginToken(body: LoginByToken) {
    return this.api
      .post('login-token-verify', {
        ...body,
        timezone: getTimezoneOffset(),
      })
      .then(({ data }) => data);
  }

  async register(dto: RegisterSendDto) {
    const { locale, timeZone } = Intl.DateTimeFormat().resolvedOptions();
    let countryCode = '';
    const zTime = getTimezoneOffset();

    try {
      const result = await this.api
        .get('json', {
          baseURL: 'http://ip-api.com',
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then(({ data }) => data);

      countryCode = result.countryCode;
    } catch (e: any) {
      console.log(e.message);

      for (let i = 0; i < timezones.length; i++) {
        const isCurrentTimezone = timezones[i].timezones.find((el) => el === timeZone);

        if (isCurrentTimezone) {
          countryCode = timezones[i].country_code;

          break;
        }
      }
    }

    return this.api.post('register', {
      lang: locale,
      country: countryCode,
      timezone: zTime,
      ...dto,
    });
  }

  registerVerify({ authCode }: RegisterVerifyDto) {
    return this.api.post('register-verify', {
      authCode
    })
  }
  @CatchAll()
  getUser() {
    return this.api.get('user');
  }

  @CatchAll()
  checkAuth() {
    return this.api.get('checkAuth');
  }

  logout() {
    return this.api.post('logout');
  }
}

function getTimezoneOffset() {
  const now = new Date();
  const offset = -now.getTimezoneOffset();
  const zTime = (offset >= 0 ? '+' : '-') + offset / 60;

  return zTime;
}
