<script setup lang="ts"></script>
<template>
  <div class="profile-waba-price">
    <h4 class="profile-waba-price__title">{{ $t('profile.dialogs.title') }}</h4>
    <ul class="profile-waba-price__list">
      <li class="profile-waba-price__list-item">
        <span class="profile-waba-price__list-title">{{ $t('profile.dialogs.incoming') }}</span
        ><span class="profile-waba-price__list-value">{{ $t('profile.dialogs.forFree') }}</span>
      </li>
      <li class="profile-waba-price__list-item">
        <span class="profile-waba-price__list-title">{{ $t('profile.dialogs.marketing') }}</span
        ><span class="profile-waba-price__list-value">11,68 ₽</span>
      </li>
      <li class="profile-waba-price__list-item">
        <span class="profile-waba-price__list-title">{{ $t('profile.dialogs.authentication') }}</span
        ><span class="profile-waba-price__list-value">6,24 ₽</span>
      </li>
      <li class="profile-waba-price__list-item">
        <span class="profile-waba-price__list-title">{{ $t('profile.dialogs.utility') }}</span
        ><span class="profile-waba-price__list-value">6,95 ₽</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.profile-waba-price {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 23px 24px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
  flex-grow: 1;
}

.profile-waba-price__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.profile-waba-price__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 34px;
  row-gap: 18px;
}

.profile-waba-price__list-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.profile-waba-price__list-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--common-dark-water);
}

.profile-waba-price__list-value {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.01em;
}
</style>
