import axios, { AxiosInstance } from "axios";

import {
  Column,
  DataType,
  FetchExistParams,
  UploadBody,
} from "~/components/contacts/types";
import { CatchAll } from "~/error/catch";
import { config } from "~/config";
import { CustomersFilter, InclusionsExclusions } from "~/entities/customer";
import { FindOptions } from "~/api/index";
import {
  Contact,
  ContactField,
  CustomField,
  CustomFieldType,
  FieldOptions,
  SystemField,
  SystemFieldType,
  TagEntity,
} from "~/entities/contacts";

interface IContactsApi<TFieldsResult> {
  getTags: () => Promise<TagEntity[]>;
  getFields: () => Promise<TFieldsResult>;
}

interface GetFieldsResult {
  sysFields: SystemField[];
  customFields: CustomField[];
}

interface UpdateFieldOptionsParams {
  fieldOptions: ( { fieldId: string } & FieldOptions )[];
}

class ContactFieldToColumnAdapter {
  static adapt(contactField: GetFieldsResult): Column[] {
    const { sysFields, customFields } = contactField;

    const systemColumns: Column[] = sysFields
      .filter(
        ({ type, name }) =>
          type !== SystemFieldType.Enum &&
          name !== "tg_id" &&
          !name.includes("_at")
      )
      .map((field) => ({
        uuid: field.id,
        name: field.name,
        dataType:
          field.name === "tg_nick" ? "telegram" : (field.type as DataType),
        columnType: "sys",
        skip: false,
      }));

    const userColumns: Column[] = customFields.map((field) => {
      const numberDataType = field.type === CustomFieldType.Int && "number";
      const textDataType = field.type === CustomFieldType.Text && "string";

      return {
        uuid: field.id,
        name: field.name,
        dataType: numberDataType || textDataType || (field.type as DataType),
        columnType: "user",
        skip: false,
      };
    });

    return [...systemColumns, ...userColumns];
  }
}

class Api {
  protected readonly api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: `${config.contactsApiUrl}`,
      withCredentials: true,
      validateStatus: (status) => status >= 200 && status < 500,
    });
  }
}

export class ContactsApi extends Api implements IContactsApi<GetFieldsResult> {
  /* contacts */
  @CatchAll()
  async getContacts(filters: CustomersFilter[], { take, skip }: FindOptions) {
    return this.api
      .post<{ result: { contacts: Contact[] } }>(
        "contacts/list",
        { filters },
        { params: { take, skip } }
      )
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async getMetaContacts(filters: CustomersFilter[]) {
    return this.api
      .post<{
        result: {
          contacts: Contact[];
          countFiltered: number;
          countAll: number;
        };
      }>("contacts/list-with-meta", { filters })
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async getNoValueFields(
    body: {
      filters: CustomersFilter[];
      companyId: string;
    } & InclusionsExclusions
  ) {
    return this.api
      .post<{
        result: ContactField[];
      }>("contacts/novalue", body)
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async download(
    body: {
      filters: CustomersFilter[];
    } & InclusionsExclusions
  ) {
    return this.api
      .post("contacts/download", body, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(({ data }) => data);
  }

  /* tags */
  @CatchAll()
  async getTags() {
    return this.api
      .get<{ result: TagEntity[] }>("tags")
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async createTag(name: string) {
    return this.api
      .post<{ result: TagEntity }>("tags", { name })
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async assignContactTags(body: {
    tagIds: string[];
    filters: CustomersFilter[];
    included?: string[];
    excluded?: string[];
  }) {
    return this.api.put("tags/contacts", body).then(({ data }) => data.result);
  }

  @CatchAll()
  async removeContactTag(contactTagId: string) {
    return this.api
      .delete<{
        result: {
          success: boolean;
          tagCompletelyDeleted: boolean;
        };
      }>(`tags/contacts/${contactTagId}`)
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async removeContactTags(body: {
    tagIds: string[];
    filters: CustomersFilter[];
    included?: string[];
    excluded?: string[];
  }) {
    return this.api
      .post<{ result: boolean }>("tags/contacts/delete", body)
      .then(({ data }) => data.result);
  }

  /* fields */
  @CatchAll()
  async getFields() {
    return this.api
      .get<{
        result: GetFieldsResult;
      }>("fields")
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async addField(name: string, type: CustomFieldType) {
    return this.api
      .post(`fields`, { name, type })
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async updateField(fieldId: string, name: string) {
    return this.api
      .put(`fields/${fieldId}`, { name })
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async updateFieldOptions(payload: UpdateFieldOptionsParams["fieldOptions"]) {
    return this.api
      .post<{
        result: GetFieldsResult
      }>("fields/options", { fieldOptions: payload })
      .then(({ data }) => data.result);
  }

  @CatchAll()
  async updateFieldValues(body: {
    systemFieldId?: string;
    customFieldId?: string;
    filters: CustomersFilter[];
    value: string;
    included?: string[];
    excluded?: string[];
  }) {
    return this.api.put("fields/values", body).then(({ data }) => data.result);
  }

  @CatchAll()
  async removeField(fieldId: string) {
    return this.api.delete(`fields/${fieldId}`).then(({ data }) => data.result);
  }
}

export class UploadContactsApi
  extends Api
  implements IContactsApi<Array<Column>>
{
  private contactsApi: IContactsApi<GetFieldsResult>;

  constructor(contactsApi: IContactsApi<GetFieldsResult>) {
    super();
    this.contactsApi = contactsApi;
  }

  async getTags() {
    return this.contactsApi.getTags();
  }

  async getFields() {
    const fields = await this.contactsApi.getFields();
    return ContactFieldToColumnAdapter.adapt(fields);
  }

  @CatchAll()
  async getExist(params: FetchExistParams) {
    const field = params.type === "telegram" ? "tg_nick" : "phone";

    return this.api
      .post<{
        result: Array<{ field: "phone" | "tg_nick"; values: Contact[] }>;
      }>("/contacts/existed", [{ field, values: params.value }])
      .then(({ data }) => {
        const [field] = data.result;
        return field.values.map(({ sysFieldValues }) => {
          const [fieldValue] = sysFieldValues;

          return fieldValue.value;
        });
      });
  }

  @CatchAll()
  async upload(body: UploadBody) {
    return this.api
      .post("/contacts/upload", body)
      .then(({ data }) => data.result);
  }
}
