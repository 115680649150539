import { FieldType } from '~/entities/customer';
import { BaseCrudApi } from './abstract/base-crud.api';
import { CatchAll } from '~/error/catch';
import { FindOptions } from "~/api/index";
import { omit } from 'lodash';
import axios, { AxiosInstance } from 'axios';
import { config } from '~/config';

export class FieldsApi {
  protected readonly api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: `${config.contactsApiUrl}`,
      withCredentials: true,
      validateStatus: (status) => status >= 200 && status < 500,
    });
  }

  @CatchAll()
  async create<T>(dto: any) {
    return this.api.post<T>('fields', dto);
  }

  @CatchAll()
  async getOne<T>(id: string, query?: FindOptions) {
    return this.api.get<T>(`fields/${id}`, { params: query });
  }

  @CatchAll()
  async findMany<TResponse = any, TQuery = any>(query?: TQuery) {
    return this.api.get<TResponse>('fields', { params: query });
  }

  @CatchAll()
  async update<T extends object>(id: string, dto: T) {
    return this.api.patch(`fields/${id}`, omit(dto, ['id']));
  }

  @CatchAll()
  async delete(id: string) {
    return this.api.delete(`fields/${id}`);
  }

  @CatchAll()
  async updateMany(payload: { id: string; name: string }[]) {
    return this.api
      .post('fields/update-many', { updates: payload })
      .then(({ data }) => data);
  }

  @CatchAll()
  async addField(payload: { 
    userId: string;
    name: string;
    type: FieldType;
    valueField: string | undefined; 
  }) {
    return this.api
      .post('add-field', { ...payload })
      .then(({ data }) => data);
  }
}
