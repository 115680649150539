<script lang="ts" setup>
import {
  PButton,
  PDialog,
  IconCrossMd as CrossIcon,
  IconMailMd as MailIcon,
  IconBotMd as BotIcon,
  IconRefreshMd as RefreshIcon,
} from '@profeat/ui-kit';
import { computed } from 'vue';

import { useAuth } from '~/stores/auth.store';
import CrownIcon from '~/assets/icons/crown.png';
import { useUser } from '~/stores/user.store';

const authStore = useAuth();
const userStore = useUser();

const freeTariffTitle = computed(() => (userStore.isWzIntegration ? 'Wazzup Free' : 'PRO FREE'));

const ok = () => {
  authStore.isNewUser = false;
};
</script>

<template>
  <PDialog v-model="authStore.isNewUser" width="small">
    <template #header>
      <header class="header">
        <CrossIcon class="cursor-pointer" @click="ok" />
      </header>
    </template>

    <div class="main">
      <div class="crown">
        <div class="flex items-center justify-center">
          <img :src="CrownIcon" alt="image" />
        </div>
        <p class="mb-2">{{ $t('profile.modals.welcome.title', { tariff: freeTariffTitle }) }}</p>
        <p class="secondary" v-html="$t('profile.modals.welcome.description', { tariff: freeTariffTitle })"></p>
      </div>

      <div class="info">
        <div class="item">
          <div class="flex items-center">
            <MailIcon class="color-[#2db2e3]" />
          </div>

          <div class="description">
            <p>{{ $t('profile.modals.welcome.list.broadcast.title') }}</p>
            <p>{{ $t('profile.modals.welcome.list.broadcast.description') }}</p>
          </div>
        </div>
        <div class="item">
          <div class="flex items-center">
            <BotIcon class="color-[#2db2e3]" />
          </div>

          <div class="description">
            <p>{{ $t('profile.modals.welcome.list.chatBot.title') }}</p>
            <p>{{ $t('profile.modals.welcome.list.chatBot.description') }}</p>
          </div>
        </div>
        <div class="item">
          <div class="flex items-center">
            <RefreshIcon class="color-[#2db2e3]" />
          </div>

          <div class="description">
            <p>{{ $t('profile.modals.welcome.list.trigger.title') }}</p>
            <p>{{ $t('profile.modals.welcome.list.trigger.description') }}</p>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <footer class="footer">
        <PButton class="w-full" @click="ok">{{ $t('profile.modals.welcome.btn') }}</PButton>
      </footer>
    </template>
  </PDialog>
</template>

<style lang="scss" scoped>
.header {
  padding: 14px 19px;
  display: flex;
  justify-content: flex-end;
}

.main {
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .crown {
    p {
      color: var(--common-black);
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      line-height: 155.5%;
      letter-spacing: -0.18px;
    }
    .secondary {
      font-size: 14px;
      font-weight: 400;
      line-height: 143%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .item {
      display: flex;
      gap: 12px;

      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid var(--common-smog);

      .description {
        display: flex;
        flex-direction: column;
        gap: 2px;

        p {
          color: var(--common-black);
          font-size: 14px;
          font-weight: 600;
          line-height: 143%;

          &:last-child {
            color: var(--common-dark-water);
            font-weight: 400;
          }
        }
      }
    }
  }
}

.footer {
  margin-top: 24px;
  padding: 0 32px 32px;
}
</style>
