import { computed } from "vue"
import { SubscriptionStatus } from "~/entities/subscriptions";
import { useUser } from "~/stores/user.store";

export const useSubscription = () => {

    const userStore = useUser()

    // Активация тарифа в процессе
    const subscriptionPending = computed(() => userStore.subscription?.status === SubscriptionStatus.Pending);

    return {
        subscriptionPending,
    }
}