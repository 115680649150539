<script lang="ts" setup>
import { useNotification } from '~/stores/notification.store';

const notificationStore = useNotification();
</script>

<template>
  <div class="notifications-list" :style="{ bottom: `${24 + notificationStore.offset}px` }">
    <transition-group name="notifications">
      <BaseNotification
        v-for="notification in notificationStore.getNotifications"
        :key="notification.id"
        :notification="notification"
      />
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.notifications-list {
  position: fixed;
  right: 16px;
  z-index: $zindex-notification;
}

// notification list transitions
.notifications-enter-from {
  opacity: 0;
  transform: scale(0.6);
}
.notifications-enter-active {
  transition: all 0.4s ease;
}
.notifications-leave-to {
  opacity: 0;
  transform: scale(0.6);
}
.notifications-leave-active {
  transition: all 0.4s ease;
}
</style>
