<script setup lang="ts">
import { computed, ref } from "vue";
import { useUser } from "~/stores/user.store";
import { useRouter } from "vue-router";
import { useToast } from "@profeat/ui-kit";

const WAZZUP_URL = "https://app.wazzup24.com";

const { showToast } = useToast();
const userStore = useUser();
const router = useRouter();

const isShow = computed(
  () => userStore.isWzIntegration && userStore.isWzApiKeyRelevance === false
);

const isLoading = ref(false);
const apiKey = ref("");
const errorText = ref("");
const isBtnDisabled = ref(false);

const onBlur = () => {
  if (!apiKey.value) {
    errorText.value = "Введите API-ключ";
  } else {
    errorText.value = "";
    isBtnDisabled.value = false;
  }
};

const onClick = async () => {
  isLoading.value = true;
  try {
    const result = await userStore.updateWzApiKey(apiKey.value);
    if (result.error) {
      switch (result.error.statusCode) {
        case 400:
          errorText.value = "API-ключ не соответствует аккаунту";
          break;
        case 404:
          errorText.value = "API-ключ не найден";
          break;
        case 409:
          errorText.value =
            "API-ключ используется в другом аккаунте, обратитесь в поддержку";
          break;
      }
      isBtnDisabled.value = true;
      return;
    }
    userStore.isWzApiKeyRelevanceUpdate(true);
    showToast({ color: "success", text: "API-ключ успешно обновлен" });
    await router.push({ name: "integrationUser" });
  } catch (err) {
  } finally {
    isLoading.value = false;
  }
};

const onRedirectToWazzup = () => {
  window.location.href = WAZZUP_URL;
};
</script>

<template>
  <PDialog v-model="isShow" width="small">
    <template #header>
      <header>
        <h4>Ключ API недействителен</h4>
      </header>
    </template>
    <article>
      <p>
        Возможно, при интеграции Wazzup с CRM сгенерировался новый ключ.
        Скопируйте его в разделе «Интеграция с CRM»
        <br />и введите сюда.
      </p>
      <PInput
        :error="errorText"
        :text-size="'medium'"
        :size="'large'"
        :placeholder="'API-ключ Wazzup'"
        v-model="apiKey"
        @blur="onBlur"
      >
      </PInput>
    </article>
    <template #footer>
      <footer>
        <PButton
          :variant="'default'"
          :size="'small'"
          :color="'secondary'"
          :nuxt="false"
          :type="'button'"
          :block="false"
          :disabled="isLoading"
          @click="onRedirectToWazzup"
        >
          Перейти в Wazzup
        </PButton>
        <PButton
          :variant="'default'"
          :size="'small'"
          :color="'primary'"
          :nuxt="false"
          :type="'button'"
          :block="false"
          :disabled="!apiKey || isLoading || isBtnDisabled"
          @click="onClick"
        >
          Применить
        </PButton>
      </footer>
    </template>
  </PDialog>
</template>

<style scoped>
header {
  box-sizing: border-box;
  padding: 4px 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

header h4 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  padding: 8px 0;
}

article {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  padding: 0 16px;
}

article p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--common-asphalt);
}

footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;
  padding: 0 16px 16px 16px;
  gap: 12px;
}
</style>
