import { defineStore } from 'pinia';

interface State {
  errors: string[];
}

export const useApp = defineStore('app', {
  state: (): State => ({
    errors: [],
  }),
  // getters: {
  //   i18() {
  //     return this.;
  //   },
  // },
  actions: {
    setError(error: string) {
      this.errors.push(error);
    },

    releaseError(index: number) {
      this.errors.splice(index, 1);
    },
  },
});
