import { config } from '~/config';

export default (): void => {
  const isProd = config.nodeEnv === 'production';

  if (!isProd) {
    return;
  }

  setTimeout(() => {
    try {
      const widget = document.createElement('script');
      widget.dataset.pfId = config.widgetPfId;
      widget.src = config.widgetSrc + Date.now();
      document.head.appendChild(widget);
    } catch (e) {
      console.error('profeat widget error: ', e);
    }
  }, 2000);
};
