import { createI18n } from 'vue-i18n';

import ru from './ru.json';

const defaultLocale = 'ru';

const languages = { ru };

const messages = Object.assign(languages);
const i18n = createI18n<false>({
  locale: defaultLocale,
  messages,
  globalInjection: true,
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    ru: function (choice: number, choicesLength: number) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
  allowComposition: true,
});

const $t = i18n.global.t;
const $te = i18n.global.te;

export { i18n, $t, $te };
