import { Broadcast } from './broadcast';
import { User } from './user';

export enum TemplateComponentType {
  HEADER = 'HEADER',
  FOOTER = 'FOOTER',
  BUTTONS = 'BUTTONS',
  BODY = 'BODY',
}

/* Только для component.HEADER */
export enum TemplateComponentFormat {
  IMAGE = 'IMAGE',
  DOCUMENT = 'DOCUMENT',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
}

export const FormatWithFiles = [
  TemplateComponentFormat.DOCUMENT,
  TemplateComponentFormat.IMAGE,
  TemplateComponentFormat.VIDEO,
];
export const FileFormat: {
  [key in TemplateComponentFormat]?: { formats: string[]; size: number };
} = {
  [TemplateComponentFormat.IMAGE]: {
    formats: ['.jpg', '.jpeg', '.png'],
    size: 5,
  },
  [TemplateComponentFormat.DOCUMENT]: {
    formats: ['.pdf'],
    size: 50,
  },
  [TemplateComponentFormat.VIDEO]: {
    formats: ['.mp4', '.3gpp'],
    size: 16,
  },
};

export enum TemplateComponentButtonType {
  URL = 'URL',
  QUICK_REPLY = 'QUICK_REPLY',
  PHONE_NUMBER = 'PHONE_NUMBER',
}

export interface TemplateComponentButton {
  id: string;
  text: string;
  type: TemplateComponentButtonType;
  url?: string;
  example?: string;
  phoneNumber?: string;
  componentId: string;
}

export interface TemplateComponent {
  id: string;
  fileName?: string;

  fileSha1?: string;

  fileSize?: number;

  fileType?: string;

  text?: string;

  type: TemplateComponentType;

  example?: Record<string, any>[];

  format?: TemplateComponentFormat;

  templateId: string;

  buttons: TemplateComponentButton[];
}
export interface Template {
  id: string;
  title: string;
  text: string;
  templateGuid: string;
  templateCode?: string;
  userId: string;
  category: TemplateCategoryType;
  broadcasts: Broadcast[];
  components: TemplateComponent[];
}

export enum WazzupTemplateType {
  wabaTemplate = 'wabaTemplate',
  wazzupTemplate = 'wazzupTemplate',
}

export enum TemplateCategoryType {
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
  AUTHENTICATION = 'AUTHENTICATION',
}

export const withFile = (c: TemplateComponent) => {
  if (!c.format) {
    return false;
  }
  return FormatWithFiles.includes(c.format);
};
