import { defineStore } from 'pinia';
import { User } from '~/entities/user';
import { api } from '~/api';
import { useTemplates } from '~/stores/template.store';

interface State {
  user: User;
  isGuestView: boolean;
}

export const useUser = defineStore('user', {
  state: (): State => ({
    user: {} as User,
    isGuestView: false,
  }),

  actions: {
    setUser(payload: User) {
      this.user = payload;
    },

    setAutoRenewal(payload: boolean) {
      if (this.user.subscription) {
        this.user.subscription.autoRenew = payload;
      }
    },

    setGuestView(payload: boolean) {
      this.isGuestView = payload;
    },

    isWzApiKeyRelevanceUpdate(status: boolean) {
      this.user.isWzApiKeyRelevance = status
    },

    updateWzApiKey(key: string) {
      return api.profile.updateWzApiKey(key)
    },

    async checkWzApiKey() {
      const { fetch } = useTemplates();
      await fetch();
    }
  },

  getters: {
    getUserId: (state: State) => state.user.id,
    getUserName: (state: State) => state.user.name,
    subscription: (state: State) => state.user.subscription,
    connectedTariff: (state: State) => state.user.subscription?.tariff,
    subscriptionActiveTo: (state: State) => state.user?.chainActiveTo,
    chainAutoRenew: (state: State) => state.user?.autoRenew,
    guestView: (state: State) => state.isGuestView,
    isWzIntegration: (state: State) => state.user.isWzIntegration,
    isWzApiKeyRelevance: (state: State) => state.user.isWzApiKeyRelevance,
  },
});
