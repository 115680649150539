import { CatchWzApi } from '~/error/wz-api-catch';
import { BaseApi } from './abstract/base.api';

export class ChatsApi extends BaseApi {
  constructor() {
    super();
  }

  @CatchWzApi()
  async getIframeLink(): Promise<{url: string}> {
    return this.api.post('wazzup/iframe').then(({ data }) => data);
  }
}
