import { defineStore } from 'pinia';

import { api } from '~/api';
import { useUser } from './user.store';
import { useTariffs } from '~/stores/tariffs.store';
import { SubscriptionStatus } from '~/entities/subscriptions';

type PaymentMode = 'buy' | 'renew' | 'top-up';

interface State {
  isDialogVisible: boolean;
  tariffId?: string;
  isAutoRenew: boolean;
  mode: PaymentMode;
}

export const usePayment = defineStore('payment', {
  state: (): State => ({
    isDialogVisible: false,
    isAutoRenew: true,
    mode: 'buy',
  }),

  actions: {
    async purchase(amount?: number) {
      const currentUrl = window.location.origin;
      let paymentUrl = '';

      switch (this.mode) {
        case 'buy': {
          paymentUrl = await this.subscribe(currentUrl);
          break;
        }
        case 'renew': {
          paymentUrl = await this.renewSubscription(currentUrl);
          break;
        }
        case 'top-up': {
          paymentUrl = await this.topUpBalance({ amount: amount!, returnUrl: currentUrl });
          break;
        }
      }

      window.open(paymentUrl, '_self');
    },
    async subscribe(returnUrl: string) {
      const userStore = useUser();
      const tariffsStore = useTariffs();

      const { confirmationUrl } = await api.subscriptions
        .create<{ confirmationUrl: string }>({
          tariffId: tariffsStore.getCurrentTariff.id,
          returnUrl,
          autoRenew: this.isAutoRenew,
          userId: userStore.user.id,
        })
        .then(({ data }) => data);

      return confirmationUrl;
    },
    async renewSubscription(returnUrl: string) {
      const userStore = useUser();

      const { confirmationUrl } = await api.subscriptions.renew({
        subscriptionId: userStore.subscription?.id!,
        returnUrl,
        withAutoRenew: this.isAutoRenew,
      });

      return confirmationUrl;
    },
    async topUpBalance(payload: { amount: number; returnUrl: string }) {
      const { confirmationUrl } = await api.payments
        .create<{ confirmationUrl: string }>(payload)
        .then(({ data }) => data);

      return confirmationUrl;
    },

    setDialogVisible(payload: boolean) {
      this.isDialogVisible = payload;
    },
    setPaymentMode(payload: PaymentMode) {
      this.mode = payload;
    },
    setAutoRenew(payload: boolean) {
      this.isAutoRenew = payload;
    },
  },

  getters: {
    getAutoRenew: (state: State) => state.isAutoRenew,
    getMode: (state: State) => state.mode,
    isCurrentSubscriptionWillReset: (state: State) => {
      const userStore = useUser();
      const subscription = userStore.subscription;

      const dangerousStatuses = [
        SubscriptionStatus.Active,
        SubscriptionStatus.WaitingForChannel,
        SubscriptionStatus.Pending
      ];

      if (subscription) {
        return state.mode === 'buy' && dangerousStatuses.includes(subscription.status);
      } else return false;
    },
  },
});
