import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js';

/**
 * Список кодов для СНГ стран
 *
 * - RU - Россия
 * - UA - Украина
 * - BY - Беларусь
 * - KZ - Казахстан
 * - AM - Армения
 * - GE - Грузия
 * - AZ - Азербайджан
 * - UZ - Узбекистан
 * - TJ - Таджикистан
 * - KG - Кыргызстан
 * - TM - Туркменистан
 */
const countryCodes: Partial<Record<CountryCode, CountryCode>> = {
  RU: 'RU',
  KZ: 'KZ',
  BY: 'BY',
  UA: 'UA',
  AM: 'AM',
  GE: 'GE',
  AZ: 'AZ',
  UZ: 'UZ',
  TJ: 'TJ',
  KG: 'KG',
  TM: 'TM',
};

export const formatToInternational = (number: string): string => {
  number = number.replace(/[\s()-]/g, '');

  for (const code in countryCodes) {
    const parsedNumber = parsePhoneNumberFromString(
      number,
      code as CountryCode,
    );
    if (parsedNumber && parsedNumber.isValid()) {
      return parsedNumber.formatInternational().replace(/\D/g, '');
    }
  }

  return 'Invalid number';
};

export const transformPhoneNumber = (number: string): string => {
  return number.replace(/[\D+]/g, '').replace(/^8/g, '7');
};
