import { useUser } from "~/stores/user.store";

export const CatchWzApi = (): any => {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;
        descriptor.value = async function (...args: any[]) {
            try {
                const result = await originalMethod.apply(this, args);
                if (result?.error?.statusCode === 403) {
                    const { isWzApiKeyRelevanceUpdate } = useUser()
                    isWzApiKeyRelevanceUpdate(false)
                }
                return result
            } catch (error) {
                throw error
            }
        };

        return descriptor;
    };
};