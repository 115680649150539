import { CatchWzApi } from '~/error/wz-api-catch';
import { BaseCrudApi } from './abstract/base-crud.api';
import {
  Channel,
  ChannelsTransportType,
  WzIntegrationChannel,
} from '~/entities/channel';

export class ChannelsApi extends BaseCrudApi {
  constructor() {
    super('channels');
  }

  @CatchWzApi()
  async createChannel(
    transport: ChannelsTransportType,
    telegramBotToken?: string,
  ) {
    return this.api.post<Channel>(`${transport}`, { telegramBotToken });
  }

  async getUserChannel() {
    return this.api.get<Channel>('user');
  }

  @CatchWzApi()
  async getOrIntegrateWzChannels() {
    return this.api
      .post<WzIntegrationChannel[] | 'CHANNEL INTEGRATED'>('wazzup-integration')
      .then(({ data }) => data);
  }

  @CatchWzApi()
  async setWzChannel(body: {
    channelId: string;
    transport: ChannelsTransportType;
    title: string;
    state: string;
  }) {
    return this.api.patch<Channel>('wazzup-integration', body).then(({ data }) => data);
  }
}
