import { createApp, markRaw } from 'vue';
import App from './App.vue';

/**
 * Plugins
 */
import { createPinia } from 'pinia';
import { i18n } from '~/i18n';
import { createHead } from '@vueuse/head';
import { ProfeatUI } from '@profeat/ui-kit';
import YmInit from '~/plugins/ym';
import GtmInit from '~/plugins/gtm';
import WidgetInit from '~/plugins/profeat.widget';
import VCalendar from 'v-calendar';
import EmojiPicker from 'vue3-emoji-picker';
import './dayjs';
import SentryInit from '~/plugins/sentry';
import router from './router';

/** Styles */
import '~/styles/_root.css';
import '~/styles/index.scss';
import 'uno.css';
import 'element-plus/theme-chalk/src/notification.scss';
import 'v-calendar/dist/style.css';
import 'vue3-emoji-picker/css';
import '@profeat/ui-kit/style.css';
import '~/styles/styleFixed.css';

import { ColorsThemes } from '@profeat/ui-kit';

const app = createApp(App);
app.use(router);
app.directive('focus', {
  mounted(el) {
    el.focus();
  },
});

const head = createHead();
app.use(head);

const pinia = createPinia();
app.use(VCalendar);
app.use(EmojiPicker);
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(pinia);
app.use(i18n);

app.use({
  install(appInner) {
    appInner.config.globalProperties.$t = i18n.global.t;
    appInner.config.globalProperties.$te = i18n.global.te;
  },
});

app.use(ProfeatUI, {
  theme: ColorsThemes.cornflower,
});
YmInit({ router });
GtmInit();
WidgetInit();
SentryInit({ router, app });

// app.use(ElementPlus);
app.mount('#app');
