import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import { api } from '~/api';
import { useChannels } from './channel.store';
import { useUser } from './user.store';
import { ChannelStatus } from '~/entities/channel';
import { SubscriptionStatus } from '~/entities/subscriptions';
import { useCompany } from '~/stores/company.store';

interface State {
  isFetching: boolean;
  confirmationUrl: string;
}

export const useProfile = defineStore('profile', {
  state: (): State => ({
    isFetching: false,
    confirmationUrl: '',
  }),

  actions: {
    async fetchEditIFrame(id: string): Promise<string> {
      const { getCurrent } = useChannels();
      const channelId = id || getCurrent.channelId;
      const response = await api.profile.getEditProfileIframeLink(channelId);

      return response.link;
    },
    async initProfile() {
      this.setFetching(true);

      const { fetchChannels } = useChannels();
      await fetchChannels();
      this.setFetching(false);
    },

    setProfileScreen() {
      const userStore = useUser();

      // Первый вход
      if (this.isFirstEntry) {
        this.router.push({ name: 'tariffs' });
      } else if (userStore.user.isWzIntegration) { // если интегрированный юзер (компонент ProfileWzIndex)
        this.router.push({ name: 'integrationUser' });
      } else if (this.noChannel) { // если нет каналов (у меня одна и та же страница)
        this.router.push({ name: 'channelCreation' });
      } else {
        this.toCurrentChannel();
      }
    },
    setConfirmationUrl(url: string) {
      this.confirmationUrl = url;
    },
    setFetching(payload: boolean) {
      this.isFetching = payload;
    },
    toCurrentChannel() {
      const channelStore = useChannels();

      const id = channelStore.getCurrent.id;

      this.router.push({
        name: 'currentChannel',
        params: {
          id,
        },
      });
    },
  },

  getters: {
    getFetching: (state: State) => state.isFetching,

    // Если у пользователя нет незакрытых каналов + нет подписки или статус подписки - "закрыто"
    isFirstEntry: (): boolean => {
      const channelStore = useChannels();
      const userStore = useUser();

      const noChannels = channelStore.getNonClosedChannels;

      return (
        !noChannels.length &&
        (!userStore.subscription ||
          userStore.subscription.status === SubscriptionStatus.Closed)
      );
    },
    isInitChannel(): boolean {
      const userStore = useUser();
      const channelStore = useChannels();

      const filtered = channelStore.getNonClosedChannels;
      const isTariffView = this.router.currentRoute.value.name === 'tariffs';

      return (
        filtered[0]?.status === ChannelStatus.onModeration &&
        !userStore.subscription &&
        !isTariffView
      );
    },
    noChannel: (): boolean => {
      const channelStore = useChannels();
      return channelStore.getChannels.length < 1;
    },
    haveNoNegativeStatusChannel: (): boolean => {
      const channelStore = useChannels();
      const notDeletedChannels = channelStore.getChannels.filter(channel => ![ChannelStatus.closed, ChannelStatus.deletedByTimeout, ChannelStatus.error].includes(channel.status))
      return notDeletedChannels.length < 1;
    },
    noActiveChannel: (): boolean => {
      const channelsStore = useChannels();
      const channels = channelsStore.getNonClosedChannels;

      return !channels.find(({ status }) => status === ChannelStatus.active);
    },

    // alerts
    hasGiftTariffExpired: () => {
      const userStore = useUser();
      const subscription = userStore.subscription;

      const isExpired = subscription?.status === SubscriptionStatus.Closed;
      const isGift = Boolean(userStore.connectedTariff?.isGift);

      return isExpired && isGift;
    },
    hasTariffExpired: () => {
      const userStore = useUser();
      const connectedTariff = userStore.connectedTariff;
      const subscription = userStore.subscription;

      const isNonGift = !(connectedTariff && connectedTariff.isGift);
      const isExpired = subscription?.status === SubscriptionStatus.Closed;

      return isExpired && isNonGift;
    },
    hasExpiredSubscription() {
      const subscriptionEnd = [
        this.hasGiftTariffExpired,
        this.hasTariffExpired,
      ];

      return subscriptionEnd.some(Boolean);
    },
    isTariffWillEnd: () => {
      const userStore = useUser();

      const subscription = userStore.subscription;
      const activeTo =
        userStore.subscriptionActiveTo &&
        new Date(userStore.subscriptionActiveTo);
      const week = -7;

      const isActive =
        subscription && subscription.status === SubscriptionStatus.Active;
      const isNonAutoRenew = !(subscription && subscription.autoRenew);
      const isEnd =
        Boolean(activeTo) && dayjs(new Date()).diff(activeTo, 'day') > week;

      return Boolean(isActive) && isNonAutoRenew && isEnd;
    },
    isGiftLimitEnd: () => {
      const userStore = useUser();

      const subscription = userStore.subscription;
      const connectedTariff = userStore.connectedTariff;
      const isGift = connectedTariff?.isGift;

      return (
        Boolean(isGift) &&
        connectedTariff?.limit! <= subscription?.dialogsCount!
      );
    },
    isLimitEnd: () => {
      const userStore = useUser();

      const subscription = userStore.subscription;
      const connectedTariff = userStore.connectedTariff;
      const isNonAutoRenew = !(subscription && subscription.autoRenew);

      return (
        isNonAutoRenew && connectedTariff?.limit! <= subscription?.dialogsCount!
      );
    },
    isAutoRenewLimitEnd: () => {
      const userStore = useUser();

      const subscription = userStore.subscription;
      const connectedTariff = userStore.connectedTariff;
      const isAutoRenew = subscription && subscription.autoRenew;

      return (
        Boolean(isAutoRenew) &&
        connectedTariff &&
        subscription &&
        connectedTariff.limit > 0 &&
        connectedTariff.limit <= subscription.dialogsCount
      );
    },
    isBalanceLimitEnd: () => {
      const userStore = useUser();

      const subscription = userStore.subscription;
      const connectedTariff = userStore.connectedTariff;
      const usedDialogs = subscription?.dialogsCount;

      const onePercent = connectedTariff && connectedTariff.limit / 100;
      const usedPercent = onePercent && usedDialogs && usedDialogs / onePercent;

      return Boolean(usedPercent) && usedPercent! > 94 && usedPercent! < 100;
    },
    isDeletedChannel: () => {
      const channelStore = useChannels();
      const status = channelStore.getCurrent.status;

      return (
        status === ChannelStatus.closed ||
        status === ChannelStatus.deletedByTimeout
      );
    },
    hasNotFullAccess: () => {
      const channelStore = useChannels();
      const userStore = useUser();
      const companyStore = useCompany();

      const noSubscription = typeof userStore.subscription === 'undefined';
      const isClosedChannel =
        channelStore.getCurrent.status === ChannelStatus.tariffNotBeenPaid;
      const noBalance = companyStore.balance === 0;

      return noSubscription && isClosedChannel && noBalance;
    },
    isTariffArchived: () => {
      const userStore = useUser();

      if (userStore.subscription) {
        const tariff = userStore.subscription.tariff;

        return !tariff.isActive;
      } else return false;
    },
  },
});
