<script lang="ts" setup>
import { useVModel } from '@vueuse/core';

// Icons
import IconFriendly from '~/assets/icons/friendly.png';
import IconDashboard from '~/assets/icons/dashboard.png';
import IconVpnLock from '~/assets/icons/vpn-lock.png';

const props = defineProps<{ modelValue: boolean }>();
const emits = defineEmits<{
  (event: 'update:modelValue', payload: boolean): void;
  (event: 'back'): void;
  (event: 'add'): void;
}>();

const visibleModel = useVModel(props, 'modelValue', emits);
const onClose = () => {
  emits('update:modelValue', false);
};

const onAddChannel = () => {
  emits('add');
};

const onBack = () => {
  emits('back');
  onClose();
};
</script>

<template>
  <PDialog v-model="visibleModel" width="medium">
    <template #header>
      <header>
        <IconArrowLeftMd class="icon" @click="onBack" />
        <p>{{ $t('profile.modals.addWabaChannel.title') }}</p>
        <IconCrossMd class="icon" @click="onClose" />
      </header>
    </template>
    <div class="main">
      <h5>{{ $t('profile.modals.addWabaChannel.guide.title') }}</h5>
      <p>{{ $t('profile.modals.addWabaChannel.guide.description') }}</p>
      <p>{{ $t('profile.modals.addWabaChannel.guide.description2') }}</p>
      <ul>
        <li>
          <div class="icon">
            <img :src="IconFriendly" alt="" width="40" height="40" />
          </div>
          <div class="content">
            <h4>{{ $t('profile.modals.addWabaChannel.guide.withoutWhatsapp.title') }}</h4>
            <p>{{ $t('profile.modals.addWabaChannel.guide.withoutWhatsapp.info') }}</p>
            <a href="/">{{ $t('profile.modals.addWabaChannel.guide.withoutWhatsapp.link') }}</a>
          </div>
        </li>
        <li>
          <div class="icon">
            <img :src="IconDashboard" alt="" width="40" height="40" />
          </div>
          <div class="content">
            <h4>{{ $t('profile.modals.addWabaChannel.guide.organizationDetails.title') }}</h4>
            <p v-html="$t('profile.modals.addWabaChannel.guide.organizationDetails.info')"></p>
          </div>
        </li>
        <li>
          <div class="icon">
            <img :src="IconVpnLock" alt="" width="40" height="40" />
          </div>
          <div class="content">
            <h4>{{ $t('profile.modals.addWabaChannel.guide.vpn.title') }}</h4>
            <p>
              {{ $t('profile.modals.addWabaChannel.guide.vpn.info') }}
              <a href="/">{{ $t('profile.modals.addWabaChannel.guide.vpn.link') }}</a
              >.
            </p>
          </div>
        </li>
      </ul>
    </div>
    <template #footer>
      <footer>
        <PButton :style="{ width: '328px' }" @click="onAddChannel">{{
          $t('profile.modals.addWabaChannel.btn')
        }}</PButton>
      </footer>
    </template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 12px 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

header p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

header .icon {
  cursor: pointer;
}

.main {
  box-sizing: border-box;
  padding: 24px;
}

.main > h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
}

.main > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--common-dark-water);
}

.main > ul {
  margin-top: 24px;
  list-style: none;
}

.main > ul > li {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  box-sizing: border-box;
  padding: 24px 0;
}

.main > ul > li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.main > ul > li:first-child {
  padding-top: 12px;
}

.main > ul > li:last-child {
  padding-bottom: 0;
}

.content > h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.content > p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--common-dark-water);
}

.content a {
  color: var(--el-color-primary) !important;
}

footer {
  box-sizing: border-box;
  padding: 8px 0 40px;
  text-align: center;
}
</style>
