export const roundNumber = (num: number, digits?: number): string => {
  const rounded = Math.ceil(num * 100) / 100;
  const decimalPlaces = rounded % 1 === 0 ? 0 : 2;

  return rounded
    .toFixed(digits || decimalPlaces)
    .split('.')
    .join(',');
};

export const getSeparatorNumber = (num: string | number): string => {
  return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const isUrl = (str: string): boolean => {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

  return urlPattern.test(str);
};
