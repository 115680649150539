import { useToast } from '@profeat/ui-kit';
import { defineStore } from 'pinia';

import { api } from '~/api';
import {
  Channel,
  ChannelStatus,
  ChannelsTransportType,
  WzIntegrationChannel,
} from '~/entities/channel';
import { NotificationType, useNotification } from '~/stores/notification.store';
import { $t } from '~/i18n';
import { useAuth } from '~/stores/auth.store';

type Channels = Array<Channel>;

interface State {
  channels: Channels;
  wzChannels: WzIntegrationChannel[];
  currentChannel: Channel;
  isFetching: boolean;
  telegramError: string;
}

export const useChannels = defineStore('channels', {
  state: (): State => ({
    channels: [],
    currentChannel: {
      title: '',
      channelId: '',
      status: ChannelStatus.onModeration,
      id: '',
      createdAt: new Date(),
      userId: '',
      transport: ChannelsTransportType.wapi,
      updatedAt: new Date(),
      verificationLink: '',
      isActivated: false,
      tier: '',
      countMessagePerDay: '',
      subscriptionExpiredAt: null,
    },
    isFetching: false,
    wzChannels: [],
    telegramError: '',
  }),

  actions: {
    async fetchChannels() {
      const response = await api.channels.findMany();
      const hasChannels = Array.isArray(response?.data);

      if (hasChannels) {
        this.channels = response.data;
        await this.fetchUserChannel();
      }
    },
    async integrateWzChannels() {
      const result = await api.channels.getOrIntegrateWzChannels();
      if (result !== 'CHANNEL INTEGRATED') {
        this.wzChannels = result;
      }
    },
    async addWabaChannel() {
      if (this.isFetching) return;
      this.setFetching(true);

      try {
        const { data } = await api.channels.createChannel(
          ChannelsTransportType.wapi,
        );

        this.setCurrent(data);
        window.open(data.verificationLink, '_blank');
      } catch (e: any) {
        const { pushNotification } = useNotification();
        pushNotification({
          message: e.response.data.error.message,
          type: NotificationType.error,
        });
      } finally {
        this.setFetching(false);
      }
    },
    async addTgChannel(telegramToken: string) {
      if (this.isFetching) return;
      this.setFetching(true);

      const { showToast } = useToast();

      try {
        const { data } = await api.channels.createChannel(
          ChannelsTransportType.telegram,
          telegramToken,
        );

        if (data.error) {
          this.setTelegramError($t('profile.modals.telegram.isExist'));
          return;
        }

        showToast({ color: 'success', text: $t('profile.modals.successAdded') });

        return data;
      } catch (error) {
        this.setTelegramError($t('profile.modals.telegram.notFound'));
      } finally {
        this.setFetching(false);
      }
    },
    async fetchUserChannel() {
      const response = await api.channels.getUserChannel();

      this.setCurrent(response.data);
    },
    async removeCurrentChannel(): Promise<boolean> {
      this.setFetching(true);

      const { pushNotification } = useNotification();
      const id = this.currentChannel.id;

      try {
        await api.channels.delete(id);
        this.setCurrent({
          ...this.currentChannel,
          status: ChannelStatus.closed,
        });

        return true;
      } catch (e: any) {
        pushNotification({
          message: e.response.data.error.message,
          type: NotificationType.error,
        });

        return false;
      } finally {
        this.setFetching(false);
      }
    },
    async removeChannelById(id: string): Promise<boolean> {
      this.setFetching(true);
      const { showToast } = useToast();
      try {
        await api.channels.delete(id);
        return true;
      } catch (e: any) {
        showToast({
          color: "error",
          text: e.response.data.error.message,
        });

        return false;
      } finally {
        this.setFetching(false);
      }
    },
    async setWzChannel(payload: WzIntegrationChannel) {
      const channel = await api.channels.setWzChannel({
        title: payload.name,
        channelId: payload.channelId,
        state: payload.state,
        transport: payload.transport,
      });

      this.currentChannel = channel;
      this.channels.push(channel);
    },

    setCurrent(payload: Channel) {
      this.currentChannel = payload;
    },
    setTelegramError(payload: string) {
      this.telegramError = payload;
    },
    setFetching(payload: boolean) {
      this.isFetching = payload;
    },
  },

  getters: {
    getChannels: (state: State) => state.channels,
    getCurrent: (state: State) => {
      return state.currentChannel;
    },
    getFetching: (state: State) => state.isFetching,
    getNonClosedChannels: (state: State) => {
      return state.channels.filter(
        (c) =>
          c.status !== ChannelStatus.closed &&
          c.status !== ChannelStatus.deletedByTimeout,
      );
    },
    hasErrorChannel(): boolean {
      return this.getCurrent.status === ChannelStatus.error;
    },
  },
});
