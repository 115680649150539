import { omit } from 'lodash';

import { CatchAll } from '~/error/catch';
import { FindOptions } from '..';
import { BaseApi } from './base.api';
import { CrudApi } from './crud.api';

type Entity = Record<string, any>;

export abstract class BaseCrudApi extends BaseApi implements CrudApi {
  constructor(basePath: string) {
    super(basePath);
  }

  @CatchAll()
  async create<T>(dto: any) {
    return this.api.post<T>('', dto);
  }

  @CatchAll()
  async getOne<T>(id: string, query?: FindOptions) {
    return this.api.get<T>(`/${id}`, { params: query });
  }

  @CatchAll()
  async findMany<TResponse = any, TQuery = any>(query?: TQuery) {
    return this.api.get<TResponse>('', { params: query });
  }

  @CatchAll()
  async update<T extends object>(id: string, dto: T) {
    return this.api.patch(`/${id}`, omit(dto, ['id']));
  }

  @CatchAll()
  async delete(id: string) {
    return this.api.delete(`/${id}`);
  }
}
