import { ComposerTranslation } from 'vue-i18n';
import { TemplateCategoryType } from '~/entities/template';

export enum DurationUnit {
  Month = 'month',
  Day = 'day',
}

export interface Tariff {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  cost: number;
  discount: number;
  amountSaved: number;
  innerCost: number;
  extraCost: number;
  duration: number;
  durationUnit: DurationUnit;
  limit: number;
  isActive: boolean;
  isGift: boolean;
  lineId: null | string;
  hasChatBot: boolean;
  hasTriggerMailing: boolean;
  isForIntegratedUser: boolean;
}

export interface Limit {
  limit: number;
}

export type TemplateCost = {
  [key in TemplateCategoryType]: number;
}
