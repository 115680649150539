import { BaseCrudApi } from "./abstract/base-crud.api";

export class UsersApi extends BaseCrudApi {
  constructor() {
    super('Users');
  }

  async fake() {
    return this.api.get('fake');
  }
}