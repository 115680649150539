<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const isVisibleHeader = computed(
  () => !['templates', 'chats'].includes(route.name as string),
);
const background = computed(() => {
  return route.meta.background ? { background: route.meta.background } : null;
});
</script>

<template>
  <div class="main">
    <Header v-show="isVisibleHeader" />
    <div class="content" :style="background">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$header-height: 64px;

.main {
  overflow-x: hidden;
  flex: 1;

  .content {
    height: calc(100vh - $header-height);
  }
}
</style>
