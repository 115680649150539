// @ts-nocheck
import { config } from '~/config';
import { Router } from 'vue-router';

export default ({ router }: { router: Router }) => {
  const isProd = config.nodeEnv === 'production';

  if (!isProd) {
    window.createYmEvent = () => {};
    window.ym = (...args) => {
      console.info('ym called with', ...args);
    };
    return;
  }

  const tryInitPrivateMetrika = privateMetrikaFactory();

  // inject Yandex Metrica
  window['ym'] =
    window['ym'] ||
    function() {
      (window['ym'].a = window['ym'].a || []).push(arguments);
    };
  window['ym'].l = 1 * new Date();

  window.createYmEvent = (name: string, options: any) => {
    window.ym(config.ymId, name, options);
  };

  setTimeout(() => {
    (function(m, e, t, r, i, k, a) {
      (k = e.createElement(t)),
        (k.async = true),
        (k.src = r),
        (k.onload = () => {
          ym(config.ymId, 'init', {
            webvisor: true,
            clickmap: true,
            accurateTrackBounce: true,
            trackLinks: true,
          });

          setTimeout(tryInitPrivateMetrika, 0);
        });
      e.body.appendChild(k);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  }, 2000);

  router.afterEach((to, from) => {
    window.ym(config.ymId, 'hit', to.fullPath);
  });

  function privateMetrikaFactory() {
    let initialized = false;

    return function tryInit() {
      window.ym(config.ymId, 'init', {
        webvisor: true,
        clickmap: true,
        accurateTrackBounce: true,
        trackLinks: true,
      });
      initialized = true;
    };
  }
};
