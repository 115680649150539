<script setup lang="ts">
import { ref } from 'vue';
import { getSeparatorNumber } from '~/utils/string-helper';
import { useCompany } from '~/stores/company.store';

const companyStore = useCompany();

// Модалка пополнения баланса
const refillModalVisible = ref<boolean>(false);

const onClickRefillBalance = () => {
  refillModalVisible.value = true;
};
</script>

<template>
  <RefillBalance v-model="refillModalVisible" />
  <div class="profile-balance">
    <h4 class="profile-balance__title">{{ $t('balance.title') }}</h4>
    <div class="profile-balance__footer">
      <div class="profile-balance__info">
        <span class="profile-balance__amount">{{ getSeparatorNumber(companyStore.balance || 0) }} ₽</span>
        <span class="profile-balance__hint">{{ $t('balance.info') }}</span>
      </div>
      <div class="profile-balance__btn">
        <PButton :variant="'default'" :color="'secondary'" @click="onClickRefillBalance">
          {{ $t('balance.button') }}
        </PButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.profile-balance {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 23px 24px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
  flex: 1;
  min-width: 375px;
}

.profile-balance__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.profile-balance__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-balance__info {
  display: flex;
  flex-direction: column;
  min-width: 173px;
  min-height: 60px;
  justify-content: space-between;
}

.profile-balance__amount {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
}

.profile-balance__hint {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--common-asphalt);
}
</style>
