import { RouteLocation } from 'vue-router';
import { useUser } from '~/stores/user.store';

export const customersGuard = (to: RouteLocation) => {
  if (to.name === 'broadcastSender') {
    const userStore = useUser();

    const hasSubscription = !!userStore.subscription;

    if (!hasSubscription) return { name: 'profile' };
  }
};
