// @ts-nocheck
import { config } from '~/config';

export default () => {
  const isProd = config.nodeEnv === 'production';

  if (!isProd) {
    return;
  }

  setTimeout(() => {
    try {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', `${config.googleGTMId}`);
    } catch (e) {
      console.error('metrics error: ', e);
    }
  }, 2000);
};
